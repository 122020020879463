import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { mapFunctionCall, startgame } from "../../slices/authSlice";
import { usePlayedCountMutation } from "../../slices/userApiSlice";
import './Previous.css'
import { useTranslation } from "react-i18next";

import LanguageSelector from "../../components/language-selector/languageSelector";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";




const Welcome = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const { mapDatas } = useSelector((state) => state.auth);
    const { reduxDate } = useSelector((state) => state.auth);
    const [playedCount, { isLoading: isloadingCount }] = usePlayedCountMutation()
    const { t, i18n } = useTranslation();




    // const [count, setCount] = useState(0)


    const dispatch = useDispatch()
    const navigate = useNavigate()


    const fetchCountApi = () => {
        if (userInfo?.id !== undefined) {
            playedCount({ user_id: userInfo?.id, date: reduxDate })
                .then((res) => {
                    if (res?.data?.status === 200) {
                        console.log('status 200');
                    }
                    else if (res.data.status === 201) {
                        console.log(res);
                        dispatch(startgame())
                        dispatch(mapFunctionCall())
                        navigate('/game/quiz')
                        console.log('page redirect quiz page');
                    }
                    // setCount(res?.data?.data?.gamecount)
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }

    // useEffect(() => {
    //     if (mapDatas) {
    //         navigate('/game/quiz')
    //     }
    // }, [mapDatas])


    useEffect(() => {

        fetchCountApi()
    }, [userInfo?.id])



    const navbarIndicator = [
        {
            id: 1,
            name: 'electoral',
            url: '/auth/electrol',
            svgImage: `<svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7582 2.17295C10.6099 0.7978 8.05533 0 5.31592 0C3.7024 0 2.15126 0.276931 0.708882 0.786738C0.284043 0.936897 0 1.33855 0 1.78915V21.9896C0 22.3348 0.167527 22.6584 0.449314 22.8577C0.731102 23.057 1.09208 23.1071 1.41749 22.9921C2.63544 22.5616 3.94712 22.3269 5.31592 22.3269C8.14401 22.3269 10.7357 23.3294 12.7582 25V2.17295Z" fill="#F2F2F2"/>
        <path d="M14.8846 25C16.9071 23.3294 19.4988 22.3269 22.3269 22.3269C23.6957 22.3269 25.0073 22.5616 26.2253 22.9921C26.5507 23.1071 26.9117 23.057 27.1935 22.8577C27.4753 22.6584 27.6428 22.3348 27.6428 21.9896V1.78915C27.6428 1.33855 27.3587 0.936897 26.9339 0.786738C25.4915 0.276931 23.9404 0 22.3269 0C19.5874 0 17.0328 0.797801 14.8846 2.17296V25Z" fill="#F2F2F2"/>
        </svg>
        `
        },
        {
            id: 2,
            name: 'stats',
            url: '/auth/profile',
            svgImage: `<svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.28125 25.1875H1.65625C1.33302 25.1875 1.02302 25.0591 0.794464 24.8305C0.565904 24.602 0.4375 24.292 0.4375 23.9688V16.6562C0.4375 16.333 0.565904 16.023 0.794464 15.7945C1.02302 15.5659 1.33302 15.4375 1.65625 15.4375H3.28125C3.60448 15.4375 3.91448 15.5659 4.14304 15.7945C4.3716 16.023 4.5 16.333 4.5 16.6562V23.9688C4.5 24.292 4.3716 24.602 4.14304 24.8305C3.91448 25.0591 3.60448 25.1875 3.28125 25.1875ZM14.6562 25.1875H13.0312C12.708 25.1875 12.398 25.0591 12.1695 24.8305C11.9409 24.602 11.8125 24.292 11.8125 23.9688V11.7812C11.8125 11.458 11.9409 11.148 12.1695 10.9195C12.398 10.6909 12.708 10.5625 13.0312 10.5625H14.6562C14.9795 10.5625 15.2895 10.6909 15.518 10.9195C15.7466 11.148 15.875 11.458 15.875 11.7812V23.9688C15.875 24.292 15.7466 24.602 15.518 24.8305C15.2895 25.0591 14.9795 25.1875 14.6562 25.1875ZM20.3438 25.1875H18.7188C18.3955 25.1875 18.0855 25.0591 17.857 24.8305C17.6284 24.602 17.5 24.292 17.5 23.9688V6.09375C17.5 5.77052 17.6284 5.46052 17.857 5.23196C18.0855 5.0034 18.3955 4.875 18.7188 4.875H20.3438C20.667 4.875 20.977 5.0034 21.2055 5.23196C21.4341 5.46052 21.5625 5.77052 21.5625 6.09375V23.9688C21.5625 24.292 21.4341 24.602 21.2055 24.8305C20.977 25.0591 20.667 25.1875 20.3438 25.1875ZM8.96875 25.1875H7.34375C7.02052 25.1875 6.71052 25.0591 6.48196 24.8305C6.2534 24.602 6.125 24.292 6.125 23.9688V2.03125C6.125 1.70802 6.2534 1.39802 6.48196 1.16946C6.71052 0.940904 7.02052 0.8125 7.34375 0.8125H8.96875C9.29198 0.8125 9.60198 0.940904 9.83054 1.16946C10.0591 1.39802 10.1875 1.70802 10.1875 2.03125V23.9688C10.1875 24.292 10.0591 24.602 9.83054 24.8305C9.60198 25.0591 9.29198 25.1875 8.96875 25.1875Z" fill="#F2F2F2"/>
        </svg>
        `
        },
        {
            id: 3,
            name: 'leaders',
            url: '/auth/leaderboard',
            svgImage: `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.34375 24.25C5.44792 24.25 3.83333 23.5833 2.5 22.25C1.16667 20.9167 0.5 19.3021 0.5 17.4062C0.5 15.6146 1.09917 14.0675 2.2975 12.765C3.49583 11.4625 4.99042 10.7387 6.78125 10.5938L1.75 0.5H10.5L13 5.5L15.5 0.5H24.25L19.25 10.5312C21.0208 10.6979 22.5054 11.4325 23.7038 12.735C24.9021 14.0375 25.5008 15.5842 25.5 17.375C25.5 19.2917 24.8333 20.9167 23.5 22.25C22.1667 23.5833 20.5417 24.25 18.625 24.25C18.4375 24.25 18.2446 24.2446 18.0463 24.2337C17.8479 24.2229 17.6554 24.1971 17.4688 24.1562C18.6146 23.4062 19.5104 22.4325 20.1562 21.235C20.8021 20.0375 21.125 18.7508 21.125 17.375C21.125 15.1042 20.3383 13.1825 18.765 11.61C17.1917 10.0375 15.27 9.25083 13 9.25C10.7292 9.25 8.8075 10.0367 7.235 11.61C5.6625 13.1833 4.87583 15.105 4.875 17.375C4.875 18.7917 5.16667 20.125 5.75 21.375C6.33333 22.625 7.25 23.5521 8.5 24.1562C8.3125 24.1979 8.11958 24.2242 7.92125 24.235C7.72292 24.2458 7.52958 24.2508 7.34375 24.25ZM13 23C11.4375 23 10.1092 22.4529 9.015 21.3587C7.92083 20.2646 7.37417 18.9367 7.375 17.375C7.375 15.8125 7.92208 14.4842 9.01625 13.39C10.1104 12.2958 11.4383 11.7492 13 11.75C14.5625 11.75 15.8908 12.2971 16.985 13.3912C18.0792 14.4854 18.6258 15.8133 18.625 17.375C18.625 18.9375 18.0779 20.2658 16.9837 21.36C15.8896 22.4542 14.5617 23.0008 13 23ZM10.6875 20.8125L13 19.0625L15.3125 20.8125L14.4375 17.9688L16.75 16.3125H13.9062L13 13.3125L12.0938 16.3125H9.25L11.5625 17.9688L10.6875 20.8125Z" fill="#F2F2F2"/>
        </svg>
        `
        },
        {
            id: 4,
            name: 'previousday',
            url: '/auth/previous',
            svgImage: `<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 20.6875C0.5 22.7062 2.125 24.25 4.25 24.25H21.75C23.875 24.25 25.5 22.7062 25.5 20.6875V11.1875H0.5V20.6875ZM21.75 2.875H19.25V1.6875C19.25 0.975 18.75 0.5 18 0.5C17.25 0.5 16.75 0.975 16.75 1.6875V2.875H9.25V1.6875C9.25 0.975 8.75 0.5 8 0.5C7.25 0.5 6.75 0.975 6.75 1.6875V2.875H4.25C2.125 2.875 0.5 4.41875 0.5 6.4375V8.8125H25.5V6.4375C25.5 4.41875 23.875 2.875 21.75 2.875Z" fill="#F2F2F2"/>
        </svg>
        `
        },

    ]

    const handleLoginRedirect = () => {
        const message =  {"event": "LOGIN_REQUIRED"}
        // For IOS
        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.communityMessageHandler
        ) {
            window.webkit.messageHandlers.communityMessageHandler.postMessage(message);
        }
        // communityMessageHandler is the function name handled from native
 
        //  For Android
        if (typeof window.Android !== 'undefined') {
            window.Android.performClick(JSON.stringify(message));
        }
    }

    return (
        <div >


            <div className="bg-white rounded-[30px] w-[328px] h-[581px] mx-auto mt-10 my-2" >
                <div>
                    <div className="mx-auto pt-8 flex justify-center">
                        <img className="w-[119px] h-[75px]" src="./Kaunsituency.png" alt="logo" />
                    </div>
                    <div className="pt-8 mx-auto flex justify-center">
                        <img className="w-[285px] h-[285px]" src="./dailythunti.svg" alt="logo" />
                    </div>

                    {
                        isloadingCount ?
                            <div className="w-full mx-auto h-[100px] flex justify-center items-center" ><LoadingSpinner /></div>
                            :
                            userInfo === null
                                ?
                                <>
                                    <div className="text-center  flex justify-center items-center text-black/80 py-6">
                                        <p className="px-2 text-base">எல்லா ஏரியாலையும் கில்லியா நீங்க?</p>
                                    </div>
                                    <div onClick={handleLoginRedirect} className="w-[288px] mx-auto h-[50px] text-sm rounded-[66px] text-[#F2F2F2] flex justify-center items-center gap-3 bg-gradient-to-r from-[#6308E8] to-[#AD6FD9]">
                                        <span>
                                            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.625 1.25H11.6719C11.4398 1.25 11.25 1.43984 11.25 1.67187V3.07812C11.25 3.31015 11.4398 3.5 11.6719 3.5H14.625C15.2473 3.5 15.75 4.00273 15.75 4.625V11.375C15.75 11.9973 15.2473 12.5 14.625 12.5H11.6719C11.4398 12.5 11.25 12.6898 11.25 12.9219V14.3281C11.25 14.5602 11.4398 14.75 11.6719 14.75H14.625C16.4883 14.75 18 13.2383 18 11.375V4.625C18 2.76172 16.4883 1.25 14.625 1.25ZM12.9727 8.31641L7.06641 14.2227C6.53906 14.75 5.625 14.3809 5.625 13.625V10.25H0.84375C0.376172 10.25 0 9.87383 0 9.40625V6.03125C0 5.56367 0.376172 5.1875 0.84375 5.1875H5.625V1.8125C5.625 1.05664 6.53906 0.687498 7.06641 1.21484L12.9727 7.12109C13.2996 7.45156 13.2996 7.98594 12.9727 8.31641Z" fill="#F2F2F2" />
                                            </svg>
                                        </span>
                                        <span>Sign in to minmini to play!.</span>
                                    </div>
                                </>
                                :
                                <div className={`${i18n.language === 'en' ? 'text-base' : 'text-sm'} pt-8 px-4 text-black/80 text-center mx-auto w-full`}>
                                    <p>{t("nogame")}</p>
                                </div>
                    }
                </div>
            </div>
            {
                userInfo !== null &&
                <div className="w-[320px] h-[60px] flex mx-auto mt-3 mb-6 gap-2 text-center">
                    {
                        navbarIndicator.map(({ id, name, svgImage, url }) => {
                            return (
                                <Link to={url} key={id} className="flex flex-col h-[60px]  justify-center gap-2 text-white items-center">

                                    <div dangerouslySetInnerHTML={{ __html: svgImage }} />
                                    <p className="text-[10px]">{t(name)}</p>

                                </Link>

                            )
                        })
                    }
                    <div className="mt-[5px] ml-1">
                        <LanguageSelector color='text-white' />
                    </div>
                </div>
            }

        </div>

    );
};

export default Welcome;
