import React, { useState } from 'react'
import UserIcons from '../../components/UserIcons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useGetLeaderboardQuery, useGetuserLeaderboardQuery } from '../../slices/userApiSlice'
import { LoadingSpinner } from '../../components/loading/LoadingSpinner'
import { useTranslation } from 'react-i18next'



const weakTime = [
    {
        id: 10,
        name: 'ahmed_bani',
        score: '2256',
        img: 'https://plus.unsplash.com/premium_photo-1683121366070-5ceb7e007a97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        id: 1,
        name: 'Louis_Martha25',
        score: '1356',
        img: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 2,
        name: 'Arnold_cuffaro',
        score: '1156',
        img: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 3,
        name: 'Sraha_belly',
        score: '856',
        img: 'https://images.unsplash.com/photo-1546961329-78bef0414d7c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 4,
        name: 'Eco_billy',
        score: '855',
        img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 5,
        name: 'alex_sethy',
        score: '450',
        img: 'https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 6,
        name: 'Lara Croft',
        score: '430',
        img: 'https://plus.unsplash.com/premium_photo-1688350808212-4e6908a03925?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 7,
        name: 'Stock_david',
        score: '410',
        img: 'https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 8,
        name: 'Mark_spide',
        score: '300',
        img: 'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 9,
        name: 'Lily Singh',
        score: '200',
        img: 'https://plus.unsplash.com/premium_photo-1664298528358-790433ba0815?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },

]

const Leaderboard = () => {
    const [toggle, setToggle] = useState(true)




    const { userInfo } = useSelector((state) => state.auth);
    const [count, setCount] = useState(1)
    const { data, error, isLoading } = useGetLeaderboardQuery(count);
    const { data: myPositionData, error: iserror, isLoading: loading } = useGetuserLeaderboardQuery(userInfo?.id);
    const [allData, setAllData] = useState([]);
    const [myDataEnabled, setMyDataEnabled] = useState(false);




    const { t } = useTranslation()


    const navigate = useNavigate()

    useEffect(() => {
        if (userInfo === null || userInfo?.mobile === null) {
            navigate('/');
        }
        else if (userInfo?.role === 'guest') {
            navigate('/')
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        if (data && !myDataEnabled) {
            if (count === 1) {
                setAllData(data.data);
            }
            else {
                setAllData(prevData => [...prevData, ...data.data]);
            }

        } else if (myPositionData && myDataEnabled) {
            setAllData(myPositionData.data);
        }
    }, [data, myPositionData, myDataEnabled]);

    const handleMyPosition = () => {

        if (!myDataEnabled) {
            setCount(1)
        }
        setMyDataEnabled(!myDataEnabled);
    };


    if (isLoading) {
        return <div className="md:py-16 flex justify-center items-center h-screen">
            <LoadingSpinner />
        </div>;
    }



    const viewMore = () => {
        if (!isLoading && !error) {
            setCount(count + 1);
        }
    };







  console.log(allData);


    return (
        <>
            <div className="md:py-16">
                <div onClick={handleMyPosition} className='fixed z-[10] h-[30px] rounded-[30px] px-3 text-sm flex justify-center items-center text-white bottom-7 right-7 bg-gradient-to-r from-[#8657F3] to-[#541AD9]'>{!myDataEnabled ? t('myrank') : t('gototop')}</div>
                <div className='fixed top-0 z-10 bg-gradient-to-r from-[#8657F3] to-[#541AD9] w-full'><UserIcons auth={true} name={t("leaderboard")} /></div>
                <div className='absolute lg:static left-0 right-0 top-[120px]'>
                    <div className=''>


                        <div className='w-full'>
                            <div className='w-full flex md:h-[30vh] justify-center items-center'>
                                <img className='w-full h-full' src="/leadersLogo.png" alt="leaderboard logo" />
                            </div>
                        </div>
                        <div
                            className={` bg-white overflow-y-auto`}>
                            <div className='flex flex-col gap-4 my-5 items-center'>
                                {
                                    allData?.map(({ points, name, position,user_id }) => {
                                        return (
                                            <div key={position} className={`${user_id == userInfo?.id ? 'bg-gradient-to-r from-[#8657F3] to-[#541AD9]' : 'bg-[#f5f0ff]'} w-[299px] h-[60px]  rounded-[10px] flex items-center`}>
                                                <div className='w-[50px] h-full flex justify-center items-center flex-col'>
                                                    <p className={`text-[14px] font-normal ${userInfo.id == user_id ? 'text-white' : 'text-black'}`}>{position}</p>
                                                    <img src="/leaderarrow.svg" alt={`${name}leadersvg1`} />
                                                </div>
                                                <div className={`w-[44px] h-[44px] flex justify-center items-center ${user_id == userInfo?.id ? 'bg-[#f5f0ff] text-[#541AD9]' : 'text-white bg-gradient-to-r from-[#8657F3] to-[#541AD9]'}  rounded-full overflow-hidden`}>
                                                    {name.substring(0, 2)}
                                                </div>
                                                <div className='w-[140px] pl-3 h-full flex flex-col justify-center'>
                                                    <p className={`text-xs font-medium truncate  ${userInfo.id == user_id ? 'text-white' : 'text-[#0A0028]'}`}>{name}</p>
                                                </div>
                                                <div className={`text-[20px] font-[200] ${userInfo.id == user_id ? 'text-white' : 'text-[#0A0028]'}`}>
                                                    {points}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !myDataEnabled && <div onClick={viewMore} className='disabled text-black text-xs mt-5 rounded-full px-4 flex justify-center items-center h-[30px] bg-[#fcd504] font-medium'>{t('viewmore')}</div>
                                }


                            </div>
                        </div>

                    </div>

                </div>

            </div >

            {/* <div className="md:fixed md:bottom-0  md:right-0">
                <img src="/Ad.svg" alt="" className="w-full p-6 justify-end md:w-72" />
            </div> */}
        </>
    )
}

export default Leaderboard