const cityList = [
  {
    "id": 1,
    "constituencies_name": "Puducherry",
    "tm_constituencies_name": "புதுச்சேரி",
    
  
  },
  {
    "id": 2,
    "constituencies_name": "Arakkonam",
    "tm_constituencies_name": "அரக்கோணம்",
   
  },
  {
    "id": 3,
    "constituencies_name": "Arani",
    "tm_constituencies_name": "அரணி",
   
  },
  {
    "id": 4,
    "constituencies_name": "Chennai Central",
    "tm_constituencies_name": "சென்னை மத்திய",
   
  },
  {
    "id": 5,
    "constituencies_name": "Chennai North",
    "tm_constituencies_name": "சென்னை வடக்கு",
    
  },
  {
    "id": 6,
    "constituencies_name": "Chennai South",
    "tm_constituencies_name": "சென்னை தெற்கு",
    
  },
  {
    "id": 7,
    "constituencies_name": "Chidambaram (SC)",
    "tm_constituencies_name": "சீதம்பரம் (SC)",
    
  },
  {
    "id": 8,
    "constituencies_name": "Coimbatore",
    "tm_constituencies_name": "கோயம்புத்தூர்",
  
  },
  {
    "id": 9,
    "constituencies_name": "Cuddalore",
    "tm_constituencies_name": "கடலூர்",
   
  },
  {
    "id": 10,
    "constituencies_name": "Dharmapuri",
    "tm_constituencies_name": "தர்மபுரி",
   
  },
  {
    "id": 11,
    "constituencies_name": "Dindigul",
    "tm_constituencies_name": "திண்டுக்கல்",
   
  },
  {
    "id": 12,
    "constituencies_name": "Erode",
    "tm_constituencies_name": "ஈரோடு",
   
  },
  {
    "id": 13,
    "constituencies_name": "Kallakurichi",
    "tm_constituencies_name": "கள்ளக்குறிச்சி",
   
  },
  {
    "id": 14,
    "constituencies_name": "Kancheepuram (SC)",
    "tm_constituencies_name": "காஞ்சிபுரம் (SC)",
   
  },
  {
    "id": 15,
    "constituencies_name": "Kanniyakumari",
    "tm_constituencies_name": "கன்னியாகுமரி",
   
  },
  {
    "id": 16,
    "constituencies_name": "Karur",
    "tm_constituencies_name": "கரூர்",
    
  },
  {
    "id": 17,
    "constituencies_name": "Krishnagiri",
    "tm_constituencies_name": "கிருஷ்ணகிரி",
   
  },
  {
    "id": 18,
    "constituencies_name": "Madurai",
    "tm_constituencies_name": "மதுரை",
   
  },
  {
    "id": 19,
    "constituencies_name": "Mayiladuthurai",
    "tm_constituencies_name": "மயிலாடுதுறை",
   
  },
  {
    "id": 20,
    "constituencies_name": "Nagapattinam (SC)",
    "tm_constituencies_name": "நாகப்பட்டினம் (SC)",
    
  },
  {
    "id": 21,
    "constituencies_name": "Namakkal",
    "tm_constituencies_name": "நாமக்கல்",
   
  },
  {
    "id": 22,
    "constituencies_name": "Nilgiris (SC)",
    "tm_constituencies_name": "நீலகிரி (SC)",
   
  },
  {
    "id": 23,
    "constituencies_name": "Perambalur",
    "tm_constituencies_name": "பெரம்பலூர்",
    
  },
  {
    "id": 24,
    "constituencies_name": "Pollachi",
    "tm_constituencies_name": "பொள்ளாச்சி",
   
  },
  {
    "id": 25,
    "constituencies_name": "Ramanathapuram",
    "tm_constituencies_name": "இராமநாதபுரம்",
   
  },
  {
    "id": 26,
    "constituencies_name": "Salem",
    "tm_constituencies_name": "சேலம்",
   
  },
  {
    "id": 27,
    "constituencies_name": "Sivaganga",
    "tm_constituencies_name": "சிவகங்கை",
   
  },
  {
    "id": 28,
    "constituencies_name": "Sriperumbudur",
    "tm_constituencies_name": "ஸ்ரீபெரும்புத்தூர்",
   
  },
  {
    "id": 29,
    "constituencies_name": "Tenkasi (SC)",
    "tm_constituencies_name": "தென்காசி (SC)",
   
  },
  {
    "id": 30,
    "constituencies_name": "Thanjavur",
    "tm_constituencies_name": "தஞ்சாவூர்",
   
  },
  {
    "id": 31,
    "constituencies_name": "Theni",
    "tm_constituencies_name": "தேனி",
   
  },
  {
    "id": 32,
    "constituencies_name": "Thoothukkudi",
    "tm_constituencies_name": "தூத்துக்குடி",
   
  },
  {
    "id": 33,
    "constituencies_name": "Tiruchirappalli",
    "tm_constituencies_name": "திருச்சிராப்பள்ளி",
   
  },
  {
    "id": 34,
    "constituencies_name": "Tirunelveli",
    "tm_constituencies_name": "திருநெல்வேலி",
    
  },
  {
    "id": 35,
    "constituencies_name": "Tiruppur",
    "tm_constituencies_name": "திருப்பூர்",
    
  },
  {
    "id": 36,
    "constituencies_name": "Tiruvallur (SC)",
    "tm_constituencies_name": "திருவள்ளூர் (SC)",
   
  },
  {
    "id": 37,
    "constituencies_name": "Tiruvannamalai",
    "tm_constituencies_name": "திருவண்ணாமலை",
    
  },
  {
    "id": 38,
    "constituencies_name": "Vellore",
    "tm_constituencies_name": "திருவாரூர்",
   
  },
  {
    "id": 39,
    "constituencies_name": "Viluppuram",
    "tm_constituencies_name": "வேலூர்",
   
  },
  {
    "id": 40,
    "constituencies_name": "Virudhunagar",
    "tm_constituencies_name": "விருதுநகர்",
  
  }
];
export default cityList