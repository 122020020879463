import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useCandidatelistMutation, useCongratulationsMutation } from '../../slices/userApiSlice';
import { MapRemoveBylocalstorage, RemovereduxDateFunc, mapFunctionCallFalse, quizRenders, quizRendersfalse } from '../../slices/authSlice';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { MdExitToApp } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import ElectoralSLider from '../../components/ElectoralSlider/ElectoralSLider';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Thumbs } from 'swiper/modules';



const StagePage = () => {
    const [fetchApi, mapDatas] = useOutletContext();
    const [candidateListData, setCandidateData] = useState([])
    const [congratulations, { isError, isLoading, isSuccess }] = useCongratulationsMutation()
    const [candidatelist] = useCandidatelistMutation()
    const [data, setData] = useState([])
    const [isExpanded, setIsExpanded] = useState(false);
    const [stopAnimationToggle, setStopAnimationToggle] = useState(false)
    const { userInfo } = useSelector((state) => state.auth);
    const { mapCall } = useSelector((state) => state.auth);
    const { quizRender } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const [leftSlider, setLeftSlider] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [ElectoralID, setElectoralID] = useState({
        id: "",
        tm_name: "",
        name: ""
    })

    const { t, i18n } = useTranslation()



    const handleSlider = (id, tm_name, name) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setLeftSlider(!leftSlider)
        setElectoralID({
            id, tm_name, name
        })
    }
    useEffect(() => {
        const candidateListApi = async () => {
            try {
                const res = await candidatelist({ user_id: userInfo.id, map_id: mapDatas.id }).unwrap();
                if (res.status === 201) {
                    console.log(res);

                }
                else {
                    setCandidateData(res.data);
                }


            } catch (err) {
                console.log(err);
            }
        }
        candidateListApi()
    }, [])

    const predectionRedirect = () => {
        // dispatch(quizRendersfalse())
        navigate('/game/prediction')
    }


    useEffect(() => {
        if (mapCall) {
            if (quizRender) {
                navigate('/game/quiz')
            }
        }
        else {
            navigate('/')

        }
    }, [])


    const exitGame = () => {
        dispatch(quizRendersfalse())
        dispatch(MapRemoveBylocalstorage())
        dispatch(RemovereduxDateFunc())
        dispatch(mapFunctionCallFalse())
        navigate('/auth/welcome')
    }
    const hadleRedirectLogin = () => {
        dispatch(quizRendersfalse())
        dispatch(MapRemoveBylocalstorage())
        dispatch(RemovereduxDateFunc())
        dispatch(mapFunctionCallFalse())
        navigate('/')
    }

    const dispatch = useDispatch()
    const CongratulationApi = async () => {
        try {
            const res = await congratulations({ user_id: userInfo.id, map_id: mapDatas.id }).unwrap();
            if (res.status === 201) {
                console.log(res);

            }
            else {
                setData(res.data);
            }


        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (userInfo?.id !== undefined) {
            CongratulationApi();
        }
    }, [userInfo?.id]);
    const attempts = t("howmanyattempt", { attempt: data?.attempt });


    const initialText = data?.location_details
    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    const handleExits = () => {
        dispatch(quizRendersfalse())
        dispatch(MapRemoveBylocalstorage())
        dispatch(RemovereduxDateFunc())
        dispatch(mapFunctionCallFalse())
        navigate(`/auth/leaderboard`)
    }

    return (
        <div>
            {
                isLoading ? <div className='relative flex justify-center items-center md:w-[600px] w-screen md:h-[850px] h-[800px] text-center pt-4 md:mt-2  md:py-4'><LoadingSpinner /></div>
                    :
                    <div className={`relative md:w-[600px] w-screen ${leftSlider ? ElectoralID.id === 1 ? 'h-[2600px]' : 'h-[1800px]' : ''} text-center pt-4 md:mt-2  md:py-4`}>
                        {/* <div className='w-full pb-2'>
                            <img className='mx-auto' src="/ad2.png" alt="ad2" />
                        </div> */}
                        {
                            data?.attempt === 0 ?
                                <>
                                    <p className='text-[14px] font-normal text-[#0A0028] leading-normal'>{t("roundended")}</p>
                                    <p className='text-[14px] font-normal text-[#C43D28] leading-normal'>{t("runout")}</p>
                                </>
                                :
                                <>
                                    <p className='text-[14px] font-bold text-[#E00025] leading-normal'>Daily vaanga, daily adunga! One area a day!</p>
                                    <p className='text-[14px] font-normal text-[#0A0028]/60 leading-normal p-1 px-2'>{attempts}</p>
                                </>
                        }
                        <div className='py-4 relative'>
                            {
                                showModal &&
                                <div className='absolute top-0 left-[50%] -translate-x-[50%] rounded-xl w-[300px] h-full overflow-hidden'>
                                    <div className="absolute right-2 top-2 bg-white p-2 rounded-full shadow-xl" onClick={closeModal}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.875 1.125L1.125 10.875M1.125 1.125L10.875 10.875" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </div>
                                    <div className="w-full h-full ">
                                        <img className="w-full h-full" src={selectedImage} style={{ objectFit: 'content' }} alt="zoomed hints" />
                                    </div>
                                </div>
                            }

                            <div>
                                <div className='w-full mb-1'>
                                    <div className='mx-auto w-[100px] h-[100px] rounded-[10px] overflow-hidden shadow-sm' onClick={() => openModal(data?.hints?.hintone)}>
                                        <img className='w-full h-full' style={{ objectFit: 'cover' }} src={data?.hints?.hintone} alt="hints1" />
                                    </div>
                                </div>
                                <div className='w-full flex justify-center gap-2'>
                                    <div className='w-[100px] h-[100px] rounded-[10px] overflow-hidden shadow-sm' onClick={() => openModal(data?.hints?.hinttwo)}>
                                        <img className='w-full h-full' style={{ objectFit: 'cover' }} src={data?.hints?.hinttwo} alt="hints2" />
                                    </div>
                                    <div className='w-[100px] h-[100px] rounded-[10px] overflow-hidden shadow-sm' onClick={() => openModal(data?.hints?.hintthree)}>
                                        <img className='w-full h-full' style={{ objectFit: 'cover' }} src={data?.hints?.hintthree} alt="hints3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-xs font-normal text-[#0A0028]/60 leading-normal mt-2'> {i18n.language === 'en' ? `State :${data?.state}` : `நிலை : ${data?.tm_state}`}</p>
                        <p className='text-[#0C002F] text-2xl font-normal   leading-normal pb-1'>{i18n.language === 'en' ? data?.name : data?.tm_name}</p>
                        <div className=''>
                            <div
                                className={``}>
                                <div className={`text-[#0A0028]  w-full text-[14px] px-4 md:px-6 text-center`}>
                                    <p>{i18n.language === 'en' ? data?.location_details : data?.tm_location_details}</p>
                                </div>


                            </div>
                            <div className='my-3 flex justify-center flex-col items-center gap-6'>
                                <div onClick={() => handleSlider(data?.id, data?.tm_name, data?.name)} className={`text-[#fdc504] ${i18n.language === 'en' ? 'w-[196px]' : 'w-[280px]'} h-[36px] cursor-pointer text-[14px] font-semibold rounded-[53px] border border-[#f5b91f] flex items-center justify-center`}>{t("viewelectoral")}</div>
                            </div>
                            <div className='mt-2 mb-4 relative z-0'>
                                <p className='text-[15px] text-[#0C002F] pb-4 px-2'>{t('knowyourcan')}</p>
                                <Swiper
                                    spaceBetween={1}
                                    centeredSlides={true}
                                    slidesPerView={2}
                                    breakpoints={{
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    modules={[Thumbs]}
                                    className="mySwiper2 z-10"
                                >
                                    {
                                        candidateListData?.map(({ candidates_id, candidates_img, tm_party_name, candidates_name, tm_candidates_name, party_img, party_name }) => {
                                            return (

                                                <SwiperSlide key={candidates_id} >

                                                    <div className={`w-[90%]`}  >
                                                        <div>
                                                            <div className='h-[170px] border border-[#D7D7D7] relative rounded-[15px] overflow-hidden'>
                                                                <img className='w-full h-full' style={{ objectFit: 'cover', objectPosition: '50% 0%' }} src={candidates_img} alt={candidates_name} />
                                                            </div>
                                                            <div className='flex justify-center mt-4 gap-1 items-center'>
                                                                <img className='w-[24px] h-[24px]' style={{ objectFit: 'cover' }} src={party_img} alt={party_name} />
                                                                <p className='text-[16px] text-black'>{i18n.language === 'en' ? party_name : tm_party_name}</p>
                                                            </div>
                                                            <p className='mt-1 text-center text-[14px] text-black/60'>{i18n.language === 'en' ? candidates_name : tm_candidates_name}</p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>

                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            {/* <div onClick={predectionRedirect} className={`cursor-pointer mb-5  mx-auto w-[288px] h-[50px] rounded-[66px] bg-[#fdc504]  flex justify-center items-center gap-2`}>
                                <span><svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.683 13.926L12.967 17.78C13.0961 18.1672 13.0857 18.5873 12.9378 18.9676C12.7899 19.348 12.5137 19.6647 12.157 19.863C10.786 20.626 9.064 21 7 21C4.936 21 3.214 20.626 1.843 19.863C1.48628 19.6647 1.21008 19.348 1.06217 18.9676C0.91426 18.5873 0.903942 18.1672 1.033 17.78L2.318 13.926C3.66403 14.9495 5.30902 15.5025 7 15.5C8.76 15.5 10.382 14.914 11.683 13.926ZM7 1C8.337 1 9.582 1.388 10.63 2.059C10.548 2.372 10.422 2.603 10.263 2.763C10.01 3.015 9.58 3.183 8.938 3.233L8.716 3.246L8.337 3.256C6.168 3.415 6.222 6.75 8.5 6.75C9.39 6.75 9.957 6.931 10.263 7.237C10.515 7.49 10.683 7.92 10.733 8.562L10.746 8.784L10.756 9.163C10.852 10.477 12.114 10.975 13.094 10.657C12.4345 12.0392 11.3247 13.1561 9.94667 13.8243C8.56868 14.4926 7.00442 14.6724 5.51073 14.3343C4.01704 13.9963 2.68268 13.1604 1.72672 11.9639C0.770766 10.7674 0.250027 9.28147 0.25 7.75C0.25 5.95979 0.961159 4.2429 2.22703 2.97703C3.4929 1.71116 5.20979 1 7 1ZM13.25 1C13.25 2.15 13.52 2.96 14.03 3.47C14.501 3.94 15.228 4.207 16.24 4.245L16.5 4.25C17.458 4.25 17.498 5.628 16.62 5.742L16.5 5.75C15.35 5.75 14.54 6.02 14.03 6.53C13.56 7.002 13.293 7.728 13.255 8.74L13.25 9C13.25 10 11.75 10 11.75 9C11.75 7.85 11.48 7.04 10.97 6.53C10.498 6.06 9.772 5.793 8.76 5.755L8.5 5.75C7.542 5.75 7.502 4.372 8.38 4.258L8.5 4.25C9.65 4.25 10.46 3.98 10.97 3.47C11.48 2.96 11.75 2.15 11.75 1C11.75 0 13.25 0 13.25 1Z" fill="#0A0028" />
                                </svg></span>
                                <p className="text-[#0A0028] text-[14px] font-normal">{t("predict")}</p>
                            </div> */}
                            <div onClick={handleExits} className={`cursor-pointer mb-5  mx-auto w-[288px] h-[50px] rounded-[66px] bg-[#fdc504]  flex justify-center items-center gap-2`}>
                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.85176 18.25C4.46148 18.25 3.27745 17.7611 2.29967 16.7833C1.3219 15.8055 0.833008 14.6215 0.833008 13.2312C0.833008 11.9174 1.2724 10.7828 2.15117 9.82766C3.02995 8.87249 4.12598 8.34174 5.43926 8.23541L1.74967 0.833328H8.16634L9.99967 4.49999L11.833 0.833328H18.2497L14.583 8.18958C15.8816 8.3118 16.9703 8.85049 17.8491 9.80566C18.7279 10.7608 19.167 11.8951 19.1663 13.2083C19.1663 14.6139 18.6775 15.8055 17.6997 16.7833C16.7219 17.7611 15.5302 18.25 14.1247 18.25C13.9872 18.25 13.8457 18.246 13.7003 18.2381C13.5548 18.2301 13.4136 18.2112 13.2768 18.1812C14.117 17.6312 14.774 16.9172 15.2476 16.039C15.7212 15.1608 15.958 14.2173 15.958 13.2083C15.958 11.543 15.3811 10.1338 14.2273 8.98066C13.0736 7.82749 11.6643 7.25061 9.99967 7.24999C8.3344 7.24999 6.92517 7.82688 5.77201 8.98066C4.61884 10.1344 4.04195 11.5437 4.04134 13.2083C4.04134 14.2472 4.25523 15.225 4.68301 16.1417C5.11079 17.0583 5.78301 17.7382 6.69967 18.1812C6.56217 18.2118 6.4207 18.2311 6.27526 18.239C6.12981 18.2469 5.98804 18.2506 5.85176 18.25ZM9.99967 17.3333C8.85384 17.3333 7.87973 16.9321 7.07734 16.1297C6.27495 15.3274 5.87406 14.3535 5.87467 13.2083C5.87467 12.0625 6.27587 11.0884 7.07826 10.286C7.88065 9.48361 8.85445 9.08272 9.99967 9.08333C11.1455 9.08333 12.1196 9.48452 12.922 10.2869C13.7244 11.0893 14.1253 12.0631 14.1247 13.2083C14.1247 14.3542 13.7235 15.3283 12.9211 16.1307C12.1187 16.9331 11.1449 17.3339 9.99967 17.3333ZM8.30384 15.7292L9.99967 14.4458L11.6955 15.7292L11.0538 13.6437L12.7497 12.4292H10.6643L9.99967 10.2292L9.33509 12.4292H7.24967L8.94551 13.6437L8.30384 15.7292Z" fill="#0A0028" />
                                </svg>
                                <p className="text-[#0A0028] text-[14px] font-normal">{t("viewleaderboard")}</p>
                            </div>
                        </div>
                        <ElectoralSLider leftSlider={leftSlider} handleSliderProps={handleSlider} electoralDetails={ElectoralID} stage={false} />
                    </div>
            }

        </div>
    );
}

export default StagePage;
