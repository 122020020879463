import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
    showSvgAnimation: true,
    mapDatas: localStorage.getItem('mapDatas')
    ? JSON.parse(localStorage.getItem('mapDatas'))
    : null,
    mapCall: localStorage.getItem('mapCall')
    ? JSON.parse(localStorage.getItem('mapCall'))
    : false,
    quizRender: localStorage.getItem('quizRender')
    ? JSON.parse(localStorage.getItem('quizRender'))
    : true,
    reduxDate: localStorage.getItem('reduxDate')
    ? JSON.parse(localStorage.getItem('reduxDate'))
    : null,
    startquiz:false,
    message:'Not Available',
    indiaMap:false,
    verificationId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem('userInfo');
    },
    disableSvgAnimation: (state) => {
      state.showSvgAnimation = false;
    },
    MapgetBylocalstorage: (state,action) => {
      state.mapDatas = action.payload;
      localStorage.setItem('mapDatas', JSON.stringify(action.payload));
    },
    MapRemoveBylocalstorage: (state) => {
      state.mapDatas = null;
      localStorage.removeItem('mapDatas');
    },
    mapFunctionCall: (state) => {
      state.mapCall = true;
      localStorage.setItem('mapCall', JSON.stringify(state.mapCall));
    },
    mapFunctionCallFalse: (state) => {
      state.mapCall = false;
      localStorage.setItem('mapCall', JSON.stringify(state.mapCall));
    },
    quizRenders: (state) => {
      state.quizRender = false;
      localStorage.setItem('quizRender', JSON.stringify(state.quizRender));
    },
    quizRendersfalse: (state) => {
      state.quizRender = true;
      localStorage.setItem('quizRender', JSON.stringify(state.quizRender));
    },
    reduxDateFunc: (state,action) => {
      state.reduxDate = action.payload;
      localStorage.setItem('reduxDate', JSON.stringify(action.payload));
    },
    RemovereduxDateFunc: (state) => {
      state.reduxDate = null;
      localStorage.removeItem('reduxDate');
    },
    startgame:(state) => {
      state.startquiz = true;
    },
    startgamefalse:(state) => {
      state.startquiz = false;
    },
    message: (state,action) => {
      state.message = action.payload;
    },
    indiaMapOpenAndClose: (state) => {
      state.indiaMap = !state.indiaMap;
    },
    indiaMapClose: (state) => {
      state.indiaMap = false
    },
    setVerificationId: (state, action) => {
      state.verificationId = action.payload;
    },

    
  },
});


export const { setCredentials,startgame,setVerificationId,indiaMapClose,indiaMapOpenAndClose,setProfileData,startgamefalse,message, reduxDateFunc,mapFunctionCallFalse,RemovereduxDateFunc,disableSvgAnimation,quizRenders,quizRendersfalse, logout,MapgetBylocalstorage,MapRemoveBylocalstorage,mapFunctionCall} = authSlice.actions;

export default authSlice.reducer;