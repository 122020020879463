import React from 'react'




const Help = ({ isOpen, closeModal }) => {
    return (
        <div>
            {isOpen && (
                <div id="myModal" className="fixed inset-0 bg-gray-700/90 flex items-center justify-center z-[999]">
                    <div className='relative  overflow-hidden flex justify-center  items-center'>
                        <div onClick={closeModal} className="absolute top-4 right-4 z-[999]   bg-white rounded-full p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                        <div className="relative dialog-container  h-[650px] md:w-[600px] bg-gradient-to-r from-[#8657F3] to-[#541AD9] overflow-auto rounded-3xl ">
                            <div className="ml-2 mx-auto flex  py-10 px-2 justify-center items-center text-center text-gray-500 ">
                                <div className='flex flex-col items-center gap-5'>
                                    <div className='md:w-[350px] w-[300px] h-[214px] relative'>
                                        <img className='w-full  h-full cover' src="/how1.svg" alt="how1" />
                                    </div>
                                    <div className='md:w-[350px] w-[300px] relative'>
                                        <img className='w-full h-full cover' src="/how2.svg" alt="how2" />
                                    </div>
                                    <div className='md:w-[350px] w-[300px] relative'>
                                        <img className='w-full h-full cover' src="/how3.svg" alt="how3" />
                                    </div>
                                    <div className='md:w-[350px] w-[300px]  relative pb-4'>
                                        <img className='w-full h-full cover' src="/how4.svg" alt="how4" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


export default Help