import React, { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { MapRemoveBylocalstorage, RemovereduxDateFunc, logout, quizRendersfalse } from '../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const ProfileMenu = () => {
    const [expanded, setExpanded] = useState(false);
    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { mapCall } = useSelector((state) => state.auth);
    const {t,i18n} = useTranslation()

    const toggleProfile = () => {
        setExpanded(!expanded);
    };
    const handleLogout = async () => {
                    dispatch(quizRendersfalse())
                    dispatch(MapRemoveBylocalstorage())
                    dispatch(RemovereduxDateFunc())
                    dispatch(logout(userInfo));
                    navigate('/')
                
    }
    const redirectPage = (name) => {
        if (mapCall) {
            navigate('/game/quiz')
        }
        else {
            dispatch(quizRendersfalse())
            dispatch(MapRemoveBylocalstorage())
            dispatch(RemovereduxDateFunc())
            if (name === 'previous') {
                navigate('/auth/previous')
            }
            if (name === 'profile') {
                navigate('/auth/profile')
            }
            if (name === 'welcome') {
                navigate('/')
            }
           
           
            if (name === 'electrol') {
                navigate('/auth/electrol')
            }
        }

    }
    return (
        <>
            

            <div className={`relative z-50 ${i18n.language === 'en' ? 'w-[260px]' : 'w-[280px]'} h-[55px]`}>
                <div
                    onClick={toggleProfile}
                    className={`absolute w-full top-0 rounded-[30px] transition-all bg-[#FDC504]  ease duration-300 ${expanded ? "h-[305px]" : "h-[55px]"
                        }`}
                >
                    <div className={`flex items-center cursor-pointer  transition-all  ease duration-300 ${expanded ? 'px-[12px] py-[18px]' : 'p-[5px]'}`}>
                        <div className='w-[55px] h-[42px] mt-0.5 rounded-full overflow-hidden'>
                            <img className='w-full h-full' loading='lazy' style={{ objectFit: 'cover' }} src={userInfo?.profile_img} alt="profile" />
                        </div>
                        <div className='flex items-center justify-center w-full gap-2'>
                            <div className={` truncate  text-black transition-all  ease duration-300  ${expanded ? 'text-base w-[120px]' : 'text-lg w-[130px]'} `} >{!expanded && t("hello")} <span className=' font-semibold'>{userInfo.name}</span></div>
                            <div className={`${expanded ? 'rotate-180' : 'rotate-0'} transition-all  ease duration-300`}>
                                <IoIosArrowDown />
                            </div>
                        </div>
                    </div>
                    <div className={`${!expanded ? 'opacity-0' : 'opacity-100 transition-all  ease-in-out duration-[400ms]'}`}>
                        <div className='flex justify-center'>
                            <div className={`absolute top-20  w-[80%] bg-black/20 h-[1px]`}></div>
                        </div>
                        {
                            expanded &&
                            <div className={`px-9 my-5 flex flex-col gap-4 font-medium`}>
                               
                                <div className='cursor-pointer' onClick={() => redirectPage('welcome')}>
                                    <div className='flex gap-4 items-start'>
                                        <img src="/solar_play.svg" alt="stats" />
                                        <p className={`${i18n.language === 'en' ? 'text-[16px]' : 'text-[12px]'} text-[#0A0028]`}>{t("playgame")}</p>
                                    </div>
                                </div>
                                <div className='cursor-pointer' onClick={() => redirectPage('profile')}>
                                    <div className='flex gap-4 items-start'>
                                        <img src="/stats.svg" alt="stats" />
                                        <p className={`${i18n.language === 'en' ? 'text-[16px]' : 'text-[12px]'} text-[#0A0028]`}>{t("stats")}</p>
                                    </div>
                                </div>
                               
                                <div className='cursor-pointer' onClick={() => redirectPage('electrol')}>
                                    <div className='flex gap-4 items-start'>
                                    <svg   width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2928 12.6822L12.4697 16.215C12.5881 16.57 12.5786 16.955 12.443 17.3037C12.3074 17.6524 12.0542 17.9427 11.7273 18.1245C10.4705 18.8239 8.892 19.1667 7 19.1667C5.108 19.1667 3.5295 18.8239 2.27275 18.1245C1.94576 17.9427 1.69258 17.6524 1.55699 17.3037C1.42141 16.955 1.41195 16.57 1.53025 16.215L2.70817 12.6822C3.94203 13.6204 5.44994 14.1274 7 14.125C8.61333 14.125 10.1002 13.5879 11.2928 12.6822ZM7 0.833374C8.22558 0.833374 9.36683 1.18904 10.3275 1.80412C10.2523 2.09104 10.1368 2.30279 9.99108 2.44946C9.75917 2.68046 9.365 2.83446 8.7765 2.88029L8.573 2.89221L8.22558 2.90137C6.23733 3.04712 6.28683 6.10421 8.375 6.10421C9.19083 6.10421 9.71058 6.27012 9.99108 6.55062C10.2221 6.78254 10.3761 7.17671 10.4219 7.76521L10.4338 7.96871L10.443 8.31612C10.531 9.52062 11.6878 9.97712 12.5862 9.68562C11.9816 10.9526 10.9643 11.9765 9.70112 12.589C8.43795 13.2016 7.00405 13.3664 5.63484 13.0565C4.26562 12.7466 3.04246 11.9804 2.16616 10.8836C1.28987 9.78685 0.812525 8.42472 0.8125 7.02087C0.8125 5.37985 1.4644 3.80603 2.62478 2.64565C3.78516 1.48527 5.35897 0.833374 7 0.833374ZM12.7292 0.833374C12.7292 1.88754 12.9767 2.63004 13.4442 3.09754C13.8759 3.52837 14.5423 3.77312 15.47 3.80796L15.7083 3.81254C16.5865 3.81254 16.6232 5.07571 15.8183 5.18021L15.7083 5.18754C14.6542 5.18754 13.9117 5.43504 13.4442 5.90254C13.0133 6.33521 12.7686 7.00071 12.7337 7.92837L12.7292 8.16671C12.7292 9.08337 11.3542 9.08337 11.3542 8.16671C11.3542 7.11254 11.1067 6.37004 10.6392 5.90254C10.2065 5.47171 9.541 5.22696 8.61333 5.19212L8.375 5.18754C7.49683 5.18754 7.46017 3.92437 8.265 3.81987L8.375 3.81254C9.42917 3.81254 10.1717 3.56504 10.6392 3.09754C11.1067 2.63004 11.3542 1.88754 11.3542 0.833374C11.3542 -0.0832926 12.7292 -0.0832926 12.7292 0.833374Z" fill="#0A0028" />
                                        </svg>
                                        <p className={`${i18n.language === 'en' ? 'text-[16px]' : 'text-[12px]'} text-[#0A0028]`}>{t("electoral")}</p>
                                    </div>
                                </div>
                                <div className='cursor-pointer' onClick={() => redirectPage('previous')} >
                                    <div className='flex gap-4 items-start'>
                                        <img src="/play.svg" alt="play" />
                                        <p className={`${i18n.language === 'en' ? 'text-[16px]' : 'text-[12px]'} text-[#0A0028]`}>{t("previous")}</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <div onClick={handleLogout} className='cursor-pointer flex gap-4 items-start'>
                                        <img src="/exit.svg" alt="stats" />
                                        <p className={`${i18n.language === 'en' ? 'text-[16px]' : 'text-[12px]'} -mt-0.5 text-[#0A0028]`}>{t("logout")}</p>
                                    </div>
                                </div> */}
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProfileMenu