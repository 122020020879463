import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Help from './help/Help';
import LanguageSelector from './language-selector/languageSelector';
import { useTranslation } from 'react-i18next';
import { MapRemoveBylocalstorage, RemovereduxDateFunc, mapFunctionCallFalse, quizRendersfalse } from '../slices/authSlice';



const MobileNav = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const { mapCall } = useSelector((state) => state.auth);
    const [isOpen, setIsOpen] = useState(false);




    const closeModal = () => {
        setIsOpen(false);
    };

    const { t,i18n } = useTranslation()
    const dispatch = useDispatch()

    const navbarIndicator = [
        {
            id: 1,
            name: 'electoral',
            url: '/auth/electrol',
            svgImage: `<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.17246 1.44668C7.74028 0.529914 6.03721 -0.00195312 4.21094 -0.00195312C3.13526 -0.00195312 2.10116 0.182667 1.13958 0.522539C0.856354 0.622645 0.666992 0.890417 0.666992 1.19081V14.6578C0.666992 14.8879 0.778677 15.1037 0.966535 15.2365C1.15439 15.3694 1.39505 15.4028 1.61198 15.3261C2.42395 15.0391 3.29841 14.8826 4.21094 14.8826C6.09633 14.8826 7.82411 15.551 9.17246 16.6647V1.44668Z" fill="#19072D"/>
            <path d="M10.59 16.6647C11.9384 15.551 13.6662 14.8826 15.5516 14.8826C16.4641 14.8826 17.3385 15.0391 18.1505 15.3261C18.3675 15.4028 18.6081 15.3694 18.796 15.2365C18.9838 15.1037 19.0955 14.8879 19.0955 14.6578V1.19081C19.0955 0.890416 18.9061 0.622645 18.6229 0.522539C17.6613 0.182667 16.6272 -0.00195312 15.5516 -0.00195312C13.7253 -0.00195312 12.0222 0.529914 10.59 1.44668V16.6647Z" fill="#19072D"/>
            </svg>            
        `
        },
        {
            id: 2,
            name: 'stats',
            url: '/auth/profile',
            svgImage: `<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1748 18.4062H0.987305C0.751096 18.4062 0.524562 18.3124 0.357538 18.1454C0.190513 17.9784 0.0966797 17.7518 0.0966797 17.5156V12.1719C0.0966797 11.9357 0.190513 11.7091 0.357538 11.5421C0.524562 11.3751 0.751096 11.2812 0.987305 11.2812H2.1748C2.41101 11.2812 2.63755 11.3751 2.80457 11.5421C2.9716 11.7091 3.06543 11.9357 3.06543 12.1719V17.5156C3.06543 17.7518 2.9716 17.9784 2.80457 18.1454C2.63755 18.3124 2.41101 18.4062 2.1748 18.4062ZM10.4873 18.4062H9.2998C9.0636 18.4062 8.83706 18.3124 8.67004 18.1454C8.50301 17.9784 8.40918 17.7518 8.40918 17.5156V8.60938C8.40918 8.37317 8.50301 8.14663 8.67004 7.97961C8.83706 7.81258 9.0636 7.71875 9.2998 7.71875H10.4873C10.7235 7.71875 10.95 7.81258 11.1171 7.97961C11.2841 8.14663 11.3779 8.37317 11.3779 8.60938V17.5156C11.3779 17.7518 11.2841 17.9784 11.1171 18.1454C10.95 18.3124 10.7235 18.4062 10.4873 18.4062ZM14.6436 18.4062H13.4561C13.2198 18.4062 12.9933 18.3124 12.8263 18.1454C12.6593 17.9784 12.5654 17.7518 12.5654 17.5156V4.45312C12.5654 4.21692 12.6593 3.99038 12.8263 3.82336C12.9933 3.65633 13.2198 3.5625 13.4561 3.5625H14.6436C14.8798 3.5625 15.1063 3.65633 15.2733 3.82336C15.4403 3.99038 15.5342 4.21692 15.5342 4.45312V17.5156C15.5342 17.7518 15.4403 17.9784 15.2733 18.1454C15.1063 18.3124 14.8798 18.4062 14.6436 18.4062ZM6.33105 18.4062H5.14355C4.90735 18.4062 4.68081 18.3124 4.51379 18.1454C4.34676 17.9784 4.25293 17.7518 4.25293 17.5156V1.48438C4.25293 1.24817 4.34676 1.02163 4.51379 0.854608C4.68081 0.687583 4.90735 0.59375 5.14355 0.59375H6.33105C6.56726 0.59375 6.7938 0.687583 6.96082 0.854608C7.12785 1.02163 7.22168 1.24817 7.22168 1.48438V17.5156C7.22168 17.7518 7.12785 17.9784 6.96082 18.1454C6.7938 18.3124 6.56726 18.4062 6.33105 18.4062Z" fill="#19072D"/>
            </svg>            
        `
        },
        {
            id: 3,
            name: 'leaders',
            url: '/auth/leaderboard',
            svgImage: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.16387 19.1243C4.71039 19.1243 3.47255 18.6132 2.45033 17.591C1.4281 16.5688 0.916992 15.3309 0.916992 13.8775C0.916992 12.5039 1.37635 11.3178 2.29508 10.3192C3.2138 9.3206 4.35964 8.76572 5.73262 8.65456L1.87533 0.916016H8.58366L10.5003 4.74935L12.417 0.916016H19.1253L15.292 8.60664C16.6496 8.73442 17.7878 9.2976 18.7065 10.2962C19.6253 11.2948 20.0843 12.4805 20.0837 13.8535C20.0837 15.323 19.5725 16.5688 18.5503 17.591C17.5281 18.6132 16.2823 19.1243 14.8128 19.1243C14.6691 19.1243 14.5212 19.1202 14.3691 19.1119C14.2171 19.1036 14.0695 19.0838 13.9264 19.0525C14.8048 18.4775 15.4916 17.7309 15.9868 16.8128C16.4819 15.8948 16.7295 14.9083 16.7295 13.8535C16.7295 12.1125 16.1264 10.6393 14.9202 9.43368C13.7139 8.2281 12.2407 7.62499 10.5003 7.62435C8.75935 7.62435 7.28608 8.22746 6.08049 9.43368C4.87491 10.6399 4.2718 12.1132 4.27116 13.8535C4.27116 14.9396 4.49477 15.9618 4.94199 16.9202C5.38921 17.8785 6.09199 18.5893 7.05032 19.0525C6.90658 19.0844 6.75867 19.1045 6.60662 19.1129C6.45456 19.1212 6.30634 19.125 6.16387 19.1243ZM10.5003 18.166C9.30241 18.166 8.28402 17.7466 7.44516 16.9077C6.6063 16.0689 6.18719 15.0508 6.18783 13.8535C6.18783 12.6556 6.60726 11.6372 7.44612 10.7983C8.28498 9.95949 9.30305 9.54038 10.5003 9.54102C11.6982 9.54102 12.7166 9.96045 13.5555 10.7993C14.3944 11.6382 14.8135 12.6562 14.8128 13.8535C14.8128 15.0514 14.3934 16.0698 13.5545 16.9087C12.7157 17.7475 11.6976 18.1667 10.5003 18.166ZM8.72741 16.4889L10.5003 15.1473L12.2732 16.4889L11.6024 14.3087L13.3753 13.0389H11.1951L10.5003 10.7389L9.80553 13.0389H7.62533L9.39824 14.3087L8.72741 16.4889Z" fill="#19072D"/>
            </svg>
        `
        },
        {
            id: 4,
            name: 'previousday',
            url: '/auth/previous',
            svgImage: `<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.882812 16.1294C0.882812 17.6771 2.12865 18.8607 3.75781 18.8607H17.1745C18.8036 18.8607 20.0495 17.6771 20.0495 16.1294V8.84609H0.882812V16.1294ZM17.1745 2.47318H15.2578V1.56276C15.2578 1.01651 14.8745 0.652344 14.2995 0.652344C13.7245 0.652344 13.3411 1.01651 13.3411 1.56276V2.47318H7.59115V1.56276C7.59115 1.01651 7.20781 0.652344 6.63281 0.652344C6.05781 0.652344 5.67448 1.01651 5.67448 1.56276V2.47318H3.75781C2.12865 2.47318 0.882812 3.65672 0.882812 5.20443V7.02526H20.0495V5.20443C20.0495 3.65672 18.8036 2.47318 17.1745 2.47318Z" fill="#19072D"/>
            </svg>
        `
        },

    ]
    const handleexit = () => {
        dispatch(quizRendersfalse())
        dispatch(MapRemoveBylocalstorage())
        dispatch(RemovereduxDateFunc())
        dispatch(mapFunctionCallFalse())
    }
    const { quizRender } = useSelector((state) => state.auth);




    return (
        <>
            <Help isOpen={isOpen} closeModal={closeModal} />

            <div className='py-5 pb-0'>
                <div className="flex justify-between p-6">
                    <div>
                        <img src="/Kausituencywhite.png" alt="whitelogo" className='h-16' />
                    </div>
                    <div className='flex gap-2.5 my-auto'>
                        <div className='mt-1'>
                            <LanguageSelector color='text-white' />
                        </div>


                        {
                            userInfo && userInfo.profile_image && !mapCall &&

                            <div className='cursor-pointer'>
                                <Link to="/auth/profile">
                                    <img
                                        style={{ objectFit: 'cover' }}
                                        src={userInfo?.profile_img}
                                        alt='user logo'
                                        className="h-10 w-10 rounded-full"
                                    />
                                </Link>
                            </div>

                        }
                    </div>
                </div>
                {
                    !quizRender &&
                    <div className='h-[35px] flex justify-center items-center gap-2 w-full '>
                        {
                            navbarIndicator.map(({ id, name, svgImage, url }) => {
                                return (
                                    <Link onClick={handleexit} to={url} key={id} className=" flex  items-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]  h-[31px] bg-[#FEBD01] rounded-[4px]">

                                        <div className='pl-1.5' dangerouslySetInnerHTML={{ __html: svgImage }} />
                                        <p className={`  ${i18n.language === 'en' ? 'text-[8px] w-[50px]' : 'w-[60px] text-[7px]'} px-2 font-[400] text-black`}>{t(name)}</p>

                                    </Link>

                                )
                            })
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default MobileNav;
