import React, { useEffect } from "react";

import MobileNav from "../../components/MobileNav";
import { Outlet, useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { useGamemapMutation, usePreviousGameMutation } from "../../slices/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { MapRemoveBylocalstorage, MapgetBylocalstorage, RemovereduxDateFunc, mapFunctionCallFalse, message, quizRendersfalse, startgamefalse, } from "../../slices/authSlice";

const GameboardLayout = () => {

    const [gameMap, {  isLoading, isSuccess }] = useGamemapMutation()
    const [previousGame, {  isLoading: preLoading, isSuccess: preSuccess }] = usePreviousGameMutation()
    const { userInfo } = useSelector((state) => state.auth);
    const { startquiz } = useSelector((state) => state.auth);
    const { mapDatas } = useSelector((state) => state.auth);
    const { reduxDate } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const fetchApi = async () => {
        dispatch(startgamefalse())
        try {
            dispatch(MapRemoveBylocalstorage())
            if (reduxDate === null) {

                const res = await gameMap({ id: userInfo?.id, access_key: userInfo?.access_key }).unwrap();
                if (res.status === 201) {
                   
                    dispatch(mapFunctionCallFalse())
                    dispatch(quizRendersfalse())
                    dispatch(MapRemoveBylocalstorage())
                    dispatch(RemovereduxDateFunc())
                    navigate('/')
                }
                else {
                    dispatch(MapgetBylocalstorage(res.data))

                }
            }
            else {
                const res = await previousGame({ user_id: userInfo?.id, access_key: userInfo?.access_key, date: reduxDate }).unwrap();
                if (res.status === 201) {
                    dispatch(message(res?.message))
                    setTimeout(() => {
                        dispatch(RemovereduxDateFunc())
                        navigate('/auth/previous')
                    }, 3000);
                    dispatch(mapFunctionCallFalse())
                    dispatch(quizRendersfalse())
                    dispatch(MapRemoveBylocalstorage())
                    dispatch(RemovereduxDateFunc())
                }
                else {
                    const mergeData = {
                        ...res?.data,
                        game:res?.game
                    }
                  
                    dispatch(MapgetBylocalstorage(mergeData ))

                }
            }



        } catch (err) {
            console.log(err);
            dispatch(mapFunctionCallFalse())
            dispatch(quizRendersfalse())
            dispatch(MapRemoveBylocalstorage())
            dispatch(RemovereduxDateFunc())
            navigate('/')
        }
    };
    useEffect(() => {
        if (startquiz) {
            fetchApi();
        }
    }, []);


    return (
        <div className="w-full min-h-screen">
            <div className="md:hidden">
                <MobileNav />
            </div>
            {
                (reduxDate === null ? isLoading : preLoading) && (reduxDate === null ? !isSuccess : !preSuccess) ? <div className="mx-auto bg-white md:w-[600px] w-screen md:my-12 z-1 rounded-3xl  items-center flex justify-center h-[70vh] md:h-[60vh]" ><LoadingSpinner /></div>
                    :
                    <div className="relative mx-auto min-h-[88vh] bg-white md:w-[600px] w-screen md:my-12 z-1 rounded-t-3xl  items-center flex flex-col overflow-hidden">
                        <Outlet fetchApi={fetchApi} context={[fetchApi, mapDatas]} />
                       
                    </div>
            }
        </div>
    );
};

export default GameboardLayout;
