import { useEffect, useState } from "react"
import { useElectoralHistoryMutation } from "../../slices/userApiSlice"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"


const ElectoralSLider = ({ leftSlider, handleSliderProps, electoralDetails,stage }) => {
    const [candidateData, setCandidateData] = useState({})
    const { i18n, t } = useTranslation()
    const { mapDatas } = useSelector((state) => state.auth)
    const handleSlider = () => {
        handleSliderProps()
    }
    const [electoralHistory, { isError, isLoading, isSuccess, isUninitialized }] = useElectoralHistoryMutation()




    useEffect(() => {
        const fetchAPi = () => {
            electoralHistory({ map_id: electoralDetails.id })
                .then((res) => {

                    if (res.data.status === 200) {
                        setCandidateData(res.data.data)
                    }
                })
                .catch((error) => {
                    console.log(error);

                })
        }
        fetchAPi()
    }, [electoralDetails.id])

    const winnerYears = t("winnerelection", { year: candidateData?.winner?.lock_sabha_year })
    const runnerYears = t("runnerelection")

   

    return (
        <div className={`absolute h-full right-0 top-0 transition  ${leftSlider ? "translate-x-[0%]" : "translate-x-[100%]"} bg-white`}>
            {
                isLoading ? <div className={`h-[1400px]  w-screen  ${stage ? 'xl:h-[600px]' : ''} bg-white flex justify-center pt-[20px]`}>Loading...</div>
                    :
                    <div className={`${stage && 'xl:flex xl:items-start xl:justify-center mt-10'} p-4 `}>
                        <div onClick={handleSlider} className='w-[34px] h-[34px] rounded-full bg-gray-300 flex justify-center items-center'>X</div>
                        <div className={`${stage && 'xl:w-1/2'}`}>
                        <p className='text-2xl text-[#0C002F] font-[500] text-center'>{i18n.language === 'en' ? electoralDetails.name : electoralDetails.tm_name}</p>
                        <div className='w-[310px] my-[28px] mx-auto'>
                            <p className='text-black text-center text-[14px] font-normal pb-2.5'>{winnerYears}</p>
                            <div className='relative w-[130px] rounded-[15px] overflow-hidden h-[130px] border  mx-auto' >
                                <img className="h-full w-full" style={{ objectFit: 'cover', objectPosition: '50% 0%' }} src={candidateData?.winner?.candidates_img} alt={candidateData?.winner?.candidates_name} />

                            </div>
                            <div className='flex items-center justify-center gap-2 pt-2'>

                                <div className=' relative w-[22px] h-[24px]'>
                                    <img className="w-full h-full" src={candidateData?.winner?.party_img} alt={candidateData?.winner?.party_name} />
                                </div>
                                <p className='font-[400] text-xl text-[#000000]'>{i18n.language === 'en' ? candidateData?.winner?.party_name : candidateData?.winner?.tm_party_name}</p>
                            </div>
                            <p className='text-base text-[#000000]/60 text-center font-normal'>{i18n.language === 'en' ? candidateData?.winner?.candidates_name : candidateData?.winner?.tm_candidates_name}</p>
                        </div>
                        <p className='px-2.5 text-[14px] text-[#000000]/70 leading-[21px]'>{i18n.language === 'en' ? candidateData?.winner?.successful_tasks : candidateData?.winner?.tm_successful_tasks}</p>
                        </div>
                      
                        <div className='md:hidden mx-auto mt-4 mb-6 w-[90%] h-[2px]  bg-[#9B78EB]/10' />
                        <div>
                            <p className='text-center text-[14px] font-normal text-black'>{runnerYears}</p>
                            <div className='grid grid-cols-2 xl:grid-cols-3 gap-4 pt-4 '>
                                {
                                    candidateData?.runner?.map(({ candidates_name, tm_candidates_name, candidates_id, candidates_img, party_name, tm_party_name, party_img }) => {
                                        return (
                                            <div key={candidates_id}>
                                                <div className='w-[130px] mx-auto h-[130px] rounded-[15px] overflow-hidden'>
                                                    <img style={{ objectFit: 'cover', objectPosition: '50% 0%' }} className="h-full w-full" src={candidates_img} alt={candidates_name} />
                                                </div>
                                                <div className='flex items-center justify-center gap-2 pt-2'>

                                                    <div className='relative w-[22px] h-[24px] '>
                                                        <img className="h-full w-full" src={party_img} alt={candidates_name} />
                                                    </div>
                                                    <p className='font-[400] text-xl text-[#000000]'>{i18n.language === 'en' ? party_name : tm_party_name}</p>
                                                </div>
                                                <p className='text-[14px] text-[#000000]/60 text-center font-normal'>{i18n.language === 'en' ? candidates_name : tm_candidates_name}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ElectoralSLider