import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MapRemoveBylocalstorage, RemovereduxDateFunc, quizRendersfalse } from '../../slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'

const Notfound = () => {
  const dispatch = useDispatch()
  useEffect(() => {
     if(!mapCall){
      dispatch(quizRendersfalse())
    dispatch(MapRemoveBylocalstorage())
    dispatch(RemovereduxDateFunc())
     }
},[])
const { mapCall } = useSelector((state) => state.auth);
  return (
    <div className='fixed top-0 z-[999] w-screen h-screen bg-gradient-to-r from-[#8657F3] justify-center to-[#541AD9] flex flex-col items-center'>
      <div className='w-[284px] h-[83px] bg-white rounded-[17px] flex justify-center items-center'>
        <div className='relative w-[156px] h-[56px]'>
          <img className='w-full h-full ' src="/404.svg" alt="logo" />
          <img className='w-[67px] h-[67px] absolute -top-[97%] -right-0.5' src="/bulberror.png" alt="bulberror" />
          <img className='bulb w-[67px] h-[67px] absolute -top-[97%] -right-0.5' src="/bulbsuccess.png" alt="bulbsuccess" />
        </div>
      </div>
      <p className='text-white text-2xl font-bold mt-[79px]'>{'Not Found (404)'}</p>
      <div className='w-[235px] mt-[14px] mb-[43px]'>
        <p className='text-[14px] font-normal text-white/80 text-center'>Sorry, the page you are looking for does not exist.</p>
      </div>
      <div className='border-b w-[90vw] border-b-white/10' />
      <div className='w-[224px] mt-[43px]'>
        <p className='text-center text-[14px] font-normal text-white'>Please visit our <Link className='text-[#fdc504] font-semibold underline' to="/">Help Centre</Link> for more information.</p>
      </div>
      <Link to={mapCall ? '/game/quiz' : '/'}>
        <div className='w-[288px] h-[50px] bg-[#fdc504] rounded-[66px] flex justify-center text-black items-center mt-[24px] text-[14px] font-normal'>
          <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
            <path d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.6967C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM2 5.25L1 5.25L1 6.75L2 6.75L2 5.25Z" fill="black" />
          </svg>
          Go back to homepage
        </div>
      </Link>
    </div>
  )
}

export default Notfound
