import React from 'react'
import { Link } from 'react-router-dom'



const CustomeIcons = ({ icon, text, link }) => {



   
    return (
        <Link to={link}>
            <div className='flex flex-col items-center mt-2 cursor-pointer'>
                <div className='p-1.5 bg-gray-200 rounded-full'>
                    <img className='w-[16px] h-[16px]' src={icon} alt={icon} />
                </div>
                <p className='text-[14px] pt-2 text-{#0A0028]'>{text}</p>
            </div>
        </Link>
    )
}

export default CustomeIcons