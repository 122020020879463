import React from 'react'
import './Loading.css'

export const LoadingSpinner = () => {
    return (

        <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
        </div>

    )
}
