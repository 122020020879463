import React, { useState } from 'react'
import UserIcons from '../../components/UserIcons'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import { useProfileMutation } from '../../slices/userApiSlice'
import { useTranslation } from 'react-i18next'




const Profile = () => {
  const [profileData,setProfileData] = useState([])
  const [profiles, { isError, isLoading, isSuccess, isUninitialized }] = useProfileMutation()
  const { userInfo } = useSelector((state) => state.auth);
  const {t,i18n} = useTranslation()



  useEffect(() => {
     const fetchAPi = () => {
      profiles({ mobile:userInfo?.mobile, access_key:userInfo?.access_key })
      .then((res) => {
        
        if(res.data.status === 200){
           setProfileData(res.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
        
      })
     }
    fetchAPi()
  },[userInfo?.access_key ,userInfo?.email])

  const data = [
    {
      id: 1,
      num: profileData?.mystats?.gamesPlayed,
      icon: '/game.svg',
      name1: t("games"),
      name2: t("played")
    },
    {
      id: 2,
      num: profileData?.mystats?.gameswin,
      icon: '/trophy.svg',
      name1: t("games"),
      name2: t("won")
    },
    {
      id: 3,
      num: profileData?.mystats?.average_win === undefined ? '...' : Math.trunc(profileData?.mystats?.average_win)+"%",
      icon: '/fire.svg',
      name1: t("average"),
      name2: t("wins")
    },
    {
      id: 4,
      num: profileData?.mystats?.best_try === undefined ? '...' : "#"+profileData?.mystats?.best_try,
      icon: '/medal.svg',
      name1: t("best"),
      name2: t("try")
    },
    {
      id: 5,
      num: profileData?.mystats?.current_steak,
      icon: '/fire.svg',
      name1: t('current'),
      name2: t('streak')
    },
    {
      id: 6,
      num: profileData?.mystats?.max_steak,
      icon: '/flash.png',
      name1: t('max'),
      name2: t('streak')
    },
  ]

 

  const navigate = useNavigate()
  useEffect(() => {
    if (userInfo === null || userInfo?.access_key === null) {
        navigate('/');
    }
    else if(userInfo?.role === 'guest'){
        navigate('/')
    }
}, [userInfo, navigate]);



const queryParams = new URLSearchParams({mobile: userInfo?.mobile,access_key: btoa(userInfo?.access_key),}).toString();

const shareHandle = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Quiz Game Leaderboard',
      text: 'Check out the current standings in the Quiz Game! Can you beat these scores?',
      url: `https://areaking.minmini.app/auth/share?${queryParams}`,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
}

  return (
    <div className="md:pt-6 pb-6 min-h-[calc(100vh-100px)]">
      <UserIcons auth={true} name={t("stats")} />
      <div className='text-center'>


        <p className='px-2 text-white text-[32px] font-normal'>{t("hello")}
          <span className='font-semibold text-[28px] ml-2 block'>
            {
                profileData?.mystats?.name
            }
          </span>
        </p>
        <div className='my-4'>
           <div className='mx-auto w-[100px] h-[100px] my-10 md:my-0 rounded-full text-white overflow-hidden'>
            <img className='w-full h-full' style={{objectFit:'cover'}}  src={profileData?.mystats?.profile_img} alt="load..." />
           </div>
          {/* <p className='text-[20px] font-medium text-white'>Novice</p> */}
        </div>
        <div className='flex justify-center items-center flex-col md:flex-row md:gap-20 w-full'>
          <div className="rounded-3xl bg-white p-4 z-[0] w-[327px] h-[350px] md:mx-0 mx-auto">
            <p className='text-lg font-medium pb-6'>{t("stats")}</p>
            <div className='grid grid-cols-3 gap-5 place-items-center'>
              {
                data?.map(({ id, name1, name2, icon, num }) => {
                  return (
                    <div key={id} className='w-[90px] h-[90px] rounded-[20px] shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex flex-col justify-center items-center'>
                      <p className='text-2xl text-[##0A0028] font-normal'>{num}</p>
                      <div className={`${icon === '' ? 'hidden' : 'w-4 h-4'} `}>
                        {
                          <img src={icon} alt={`${id}icon`} />
                        }
                      </div>
                      <p className={` text-black ${i18n.language === 'en' ? 'text-[11px]' : 'text-[8px]'}`}>{name1} <br /> {name2}</p>
                    </div>
                  )
                })
              }


            </div>
            <div className='mt-6'>
              <div onClick={shareHandle} className='mx-auto rounded-[10px] w-[110px] h-[35px] bg-[#FDC504] text-[#0A0028] flex gap-1 justify-center items-center'>
                <p>{t("share")}</p>
                <img src="/share.svg" alt="share" />
              </div>
            </div>
          </div>
          <div className='px-1 w-[327px] h-[390px] md:mx-0 mx-auto mt-10 md:mt-0'>
            <p className='text-lg font-medium text-white pb-5'>{t("besttrydistributions")}</p>
            {
          profileData && profileData?.distribution?.map(({ optioncount, optionid, optionscore },index) => {
                return (
                  <div key={optionid} className='w-full flex mb-3'>
                    <div className='relative w-[80%] bg-white h-[40px]  rounded-[15px]'>
                      <div className="text-[14px] z-10 text-black flex justify-center items-center absolute w-[24px] h-[24px] bg-[#FDC504] rounded-full top-[53%] -left-[24px] -translate-y-[50%] translate-x-[12px]">
                        {index + 1}
                      </div>
                      <div style={{ width: optionscore+'%' }} className={`bg-[#FDC504] flex justify-center   items-center text-[14px] text-[#0C002F] font-normal absolute h-full  rounded-[15px] shadow-[2px_0_1px_0_rgb(0,0,0,0.4)]`}>
                        {Math.trunc(optionscore)}%

                      </div>
                    </div>
                    <div className='mx-auto w-[15%] h-[40px] bg-white rounded-[15px] text-lg font-normal grid place-items-center'>{optioncount}</div>
                  </div>
                )
              })
            }
          </div>
        </div>


      </div>
      
    </div>

  )
}

export default Profile