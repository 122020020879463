import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import UserIcons from '../../components/UserIcons';
import './Previous.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reduxDateFunc } from '../../slices/authSlice';
import { useEffect } from 'react';
import { useGameCalenderMutation } from '../../slices/userApiSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';







const initialValue = dayjs(new Date());



const Previous = () => {
    const [highlightdates,setHighlightsDates] = useState([
        { date: "2023-11-02", game: "Success" },
        { date: "2023-11-01", game: "Success" },
        { date: "2023-11-03", game: "Panding" },
        { date: "2023-11-04", game: "Success" },
        { date: "2023-11-05", game: "Success" },
        { date: "2023-11-06", game: "Success" },
    ])

    const {t} = useTranslation()
    const { userInfo } = useSelector((state) => state.auth);
    const [gameCalender, { isError, isLoading, isSuccess, isUninitialized }] = useGameCalenderMutation()
 

    useEffect(() => {
        if(userInfo?.id !== undefined){
            gameCalender({ user_id:userInfo?.id }).unwrap()
            .then((res) => {
                setHighlightsDates(res.data)
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    },[userInfo?.id])

    console.log(highlightdates);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    function ServerDay(props) {
        const { day, outsideCurrentMonth, ...other } = props;
   
        const isMissed = highlightdates.some(entry => entry.date === day.format('YYYY-MM-DD') && (entry.game === 'Panding'))
        const isSuccess = highlightdates.some(entry => entry.date === day.format('YYYY-MM-DD') && (entry.game === 'Success'))
        const matchingItem = highlightdates.find(item => item.date === day.format('YYYY-MM-DD'));
        const topWidthMapping = {
            100: 0,
            80: 20,
            60: 40,
            40: 60,
            20: 80,
            0: 110,
          };
        const topwidth = matchingItem?.present === undefined ? 110 : topWidthMapping[matchingItem?.present]
        const styles = {
            backgroundColor:  !isMissed ? isSuccess ? '#fdc504' : '' : '#D9D9D9',
            fontSize: '14px',
            fontWeight: '700',
            position: 'relative',
            border: '1px solid #cbd5e1',
            overflow:'hidden',
            // Add other styles as needed
        };
    
        return (
            <PickersDay
                style={styles}
                {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}
            >

                {
                    isMissed &&
                    <>
                        <div style={{top: topwidth+'%'}} className='wave bg-[#fdc504] flex justify-center items-center'>

                        </div>
                        
                        <div  className='absolute w-full h-full   z-[10] rounded-full flex justify-center items-center'>
                            {day.$D}
                        </div>
                    </>
                }

            </PickersDay>

        );
    }

    React.useEffect(() => {
        if (userInfo === null ) {
            navigate('/');
        }
    }, [userInfo, navigate]);

    const handleChange = (date) => {
        
        const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
        dispatch(reduxDateFunc(formattedDate))
        navigate('/')
    }
    return (
        <div className="md:py-10">
            <UserIcons auth={true} name={t("previousgame")} />
            <div className='datePicker relative overflow-hidden rounded-[25px]' style={{direction:'ltr'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        defaultValue={initialValue}
                        disableFuture
                        onChange={(initialValue) => handleChange(initialValue)}
                        style={{ backgroundColor: "white", borderRadius: '25px' }}
                        views={['day']}
                        slots={{
                            day: ServerDay,
                        }}
                        slotProps={{
                            day: {
                                highlightdates,
                            },
                        }}
                    />
                </LocalizationProvider>
            </div>
            <div className='mx-auto w-[334px] mt-6 justify-center flex gap-3'>
                <div className='flex items-center gap-2 ml-2'>
                    <div className="rounded-full w-[20px] h-[20px] bg-[#fdc504] "></div>
                    <div className='font-medium text-[14px] text-white'>{t("complete")}</div>
                </div>
                {/* <div className='w-[120px] flex items-center gap-2'>
                    <div className=' overflow-hidden '>
                    <div className=" w-[20px] h-[10px] bg-[#d9d9d9]  rounded-[10px_10px_0_0]"></div>
                    <div className=" w-[20px] h-[10px] bg-[#fdc504] rounded-[0_0_10px_10px]"></div>
                    </div>
                    <div className='font-medium text-[14px] text-white'>Incomplete</div>
                </div> */}
                <div className='flex items-center gap-2'>
                    <div className="rounded-full w-[20px] h-[20px] bg-[#d9d9d9]"></div>
                    <div className='font-medium text-[14px] text-white'>{t("missed")}</div>
                </div>
            </div>
          
        </div>

    );
}
export default Previous