import React, { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useCityDataCompleteMutation, useCityDataMutation, useCityReloadMutation, useSearchMutation } from "../../slices/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import cityList from "../../config/city";
import { MapRemoveBylocalstorage, RemovereduxDateFunc, mapFunctionCall, mapFunctionCallFalse, message, quizRenders, quizRendersfalse } from "../../slices/authSlice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";



const QuizPage = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isCorrect, setIsCorrect] = useState('');
    const [incorrectGuesses, setIncorrectGuesses] = useState([]);
    const [checking, setChecking] = useState(true)
    const [search] = useSearchMutation()
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [isCityListVisible, setCityListVisible] = useState(true);
    const inputRef = useRef(null);
    const router = useNavigate()
    const [cityData, { isError, isLoading, isUninitialized }] = useCityDataMutation()
    const [cityDataComplete, { isLoading: completeLoading }] = useCityDataCompleteMutation()
    const [cityReload, { isSuccess }] = useCityReloadMutation()
    const { userInfo } = useSelector((state) => state.auth);

    const { quizRender } = useSelector((state) => state.auth);
    const { mapDatas } = useSelector((state) => state.auth)
    const { mapCall } = useSelector((state) => state.auth)
    const [attempt, setAttempt] = useState(0)
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cityPlaceholder, setCityPlaceholder] = useState("")

    const { i18n, t } = useTranslation();





    const [currentHintIndex, setCurrentHintIndex] = useState(0);
    const [keys, setKeys] = useState([]);
    const [values, setValues] = useState([]);
    // const [progress, setProgress] = useState(0);
    const dispatch = useDispatch()




    useEffect(() => {
        if (mapCall) {
            if (!quizRender) {
                router('/game/stage')
            }
        }
        else {
            router(-1)
        }
    }, [])



    const nextHint = () => {
        if (currentHintIndex < attempt - 1) {
            setCurrentHintIndex(currentHintIndex + 1);
        }
    };

    const prevHint = () => {
        if (currentHintIndex > 0) {
            setCurrentHintIndex(currentHintIndex - 1);
        }
    };




    // Handle changes in the search input
    const handleSearchChange = (event) => {
        const term = event.target.value;

        setSearchTerm(term);

        // Fetch cities only if the search term is not empty
        if (term.trim() !== '') {
            setCityListVisible(true);
            const filtered = cityList.filter((city) =>
                city.constituencies_name.toLowerCase().includes(term.toLowerCase()) ||
                city.tm_constituencies_name.toLowerCase().includes(term.toLowerCase())
            );

            // Sort cities based on whether they start with the search term or not in both English and Tamil names
            const sortedCities = filtered.sort((a, b) => {
                const aEnglishStartsWithTerm = a.constituencies_name.toLowerCase().startsWith(term.toLowerCase());
                const bEnglishStartsWithTerm = b.constituencies_name.toLowerCase().startsWith(term.toLowerCase());
                const aTamilStartsWithTerm = a.tm_constituencies_name.toLowerCase().startsWith(term.toLowerCase());
                const bTamilStartsWithTerm = b.tm_constituencies_name.toLowerCase().startsWith(term.toLowerCase());

                if (aEnglishStartsWithTerm && bEnglishStartsWithTerm) {
                    return 0;
                } else if (aEnglishStartsWithTerm) {
                    return -1;
                } else if (bEnglishStartsWithTerm) {
                    return 1;
                } else if (aTamilStartsWithTerm && bTamilStartsWithTerm) {
                    return 0;
                } else if (aTamilStartsWithTerm) {
                    return -1;
                } else if (bTamilStartsWithTerm) {
                    return 1;
                } else {
                    return 0;
                }
            });

            const uniqueFiltered = sortedCities.filter(
                (city) => !selectedCity.includes(city.id)
            );

            const isFullCityName = cityList.some((city) =>
                city.constituencies_name.toLowerCase() === term.toLowerCase() ||
                city.tm_constituencies_name.toLowerCase() === term.toLowerCase()
            );

            setCities(uniqueFiltered);
            setSubmitEnabled(isFullCityName);
        } else {
            setCityListVisible(false);
            setSubmitEnabled(false);
            setCities([]); // Clear the city list when the search term is empty
        }
    };


    // Handle city selection
    const handleCitySelect = (city) => {
        setSearchTerm(i18n.language === 'ta' ?  city.tm_constituencies_name : city.constituencies_name);
        setCities([city]);
        setCityListVisible(false);
        setSubmitEnabled(true);
        inputRef.current.value = i18n.language === 'ta' ? city.tm_constituencies_name : city.constituencies_name;
    };
    useEffect(() => {
        if (cities.length > 0) {
            inputRef.current.value = i18n.language === 'ta' ? cities[0]?.tm_constituencies_name : cities[0]?.constituencies_name;
        }

    }, [i18n.language]);


    const fetchAPis = () => {
        try {
            cityReload({ user_id: userInfo?.id, map_id: mapDatas?.id, status: mapDatas?.game }).unwrap()
                .then((res) => {
                    if (res?.status === 201) {
                        // setAttempt(6)
                        // console.log(res);
                    }
                    else {
                        const allKeys = [];
                        const allValues = [];

                        mapDatas?.hints.forEach((hint) => {
                            Object.entries(hint).forEach(([key, value]) => {
                                allKeys.push(key);
                                allValues.push(value);
                            });
                        });
                        const transformedArray = allKeys.map(item => {
                            // Replace underscores with spaces and capitalize the first letter of each word
                            const transformedItem = item
                                .replace(/_/g, ' ')
                                .replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());

                            return transformedItem;
                        });

                        const filteredKeysEnglish = transformedArray.filter(item => !item.startsWith('Tm '));

                        const englishWords = allValues.filter((item, index) => index % 2 === 0 && item);
                        const tamilhWords = allValues.filter((item, index) => index % 2 !== 0 && item);
                        setKeys(filteredKeysEnglish);
                        setValues(i18n.language === 'ta' ? tamilhWords : englishWords);
                        setIncorrectGuesses(res?.data);
                        setAttempt(res?.data[0]?.attempt_count);

                        setCityPlaceholder(i18n.language === 'ta' ? mapDatas?.tm_kaunsituencyname?.slice(0, res?.data[0]?.attempt_count)  : mapDatas?.kaunsituencyname?.slice(0, res?.data[0]?.attempt_count) )





                    }

                })
                .catch((error) => {
                    console.log(error);
                    if (userInfo?.id !== undefined) {
                        dispatch(mapFunctionCallFalse())
                        dispatch(quizRendersfalse())
                        dispatch(MapRemoveBylocalstorage())
                        dispatch(RemovereduxDateFunc())
                        dispatch(message(error?.status))
                        router('/')
                    }

                })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAPis()
    }, [])
    useEffect(() => {
        fetchAPis()
    }, [i18n.language])
    const checkCity = () => {
        setChecking(false)
        setCityListVisible(false)
        // const apiCall = mapDatas?.game = "complete" ? cityDataComplete : cityData;
        if (mapDatas?.game !== undefined && mapDatas?.game === 'complete') {
            try {
                cityDataComplete({ user_id: userInfo.id, searchcityid: cities[0].id, map_id: mapDatas.id }).unwrap()
                    .then((res) => {
                        fetchAPis()
                        if (res.status === 201) {
                            dispatch(quizRenders())
                            router('/game/stage')
                        }
                        if (res?.data?.match === 'Wrong') {
                            setIsCorrect(res.data?.match)
                            setSelectedCity([...selectedCity, cities[0].id]);
                            setCurrentHintIndex(attempt)

                            setCityPlaceholder(i18n.language === 'ta' ? mapDatas?.tm_kaunsituencyname?.slice(0, attempt) : mapDatas?.kaunsituencyname?.slice(0, attempt) )



                            setTimeout(() => {
                                setChecking(true)
                                setSearchTerm("")
                                if (res.data.button === 'final') {
                                    dispatch(quizRenders())
                                    router('/game/stage')
                                }
                            }, 1500);

                        }
                        if (res?.data?.match === 'Success') {
                            setIsCorrect(res.data.match);
                            setTimeout(() => {
                                setChecking(true)
                                setSearchTerm("")
                                dispatch(quizRenders())
                                router('/game/stage')
                            }, 3000);

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        dispatch(mapFunctionCallFalse())
                        dispatch(quizRendersfalse())
                        dispatch(MapRemoveBylocalstorage())
                        dispatch(RemovereduxDateFunc())
                        dispatch(message(error?.status))
                        router('/')
                    })

                if (isCorrect === 'Wrong') {
                    if ('vibrate' in navigator) {
                        // Vibrate for 200 milliseconds
                        navigator.vibrate(1000);
                    } else {
                        console.warn('Vibration API not supported in this browser.');
                    }
                }

            } catch (err) {
                console.log(err);
                if (err.name === 'SyntaxError') {
                    // Handle parsing error, e.g., show a user-friendly message
                    console.error('Parsing error. The server did not return valid JSON.');
                }
            }
        }
        else {
            try {
                cityData({ user_id: userInfo.id, searchcityid: cities[0].id, map_id: mapDatas.id }).unwrap()
                    .then((res) => {
                        fetchAPis()
                        if (res.status === 201) {
                            dispatch(quizRenders())
                            router('/game/stage')
                        }
                        if (res?.data?.match === 'Wrong') {
                            setIsCorrect(res.data?.match)
                            setSelectedCity([...selectedCity, cities[0].id]);
                            setCurrentHintIndex(attempt)
                            setCityPlaceholder(i18n.language === 'ta'  ? mapDatas?.tm_kaunsituencyname?.slice(0, attempt) :  mapDatas?.kaunsituencyname?.slice(0, attempt))

                            setTimeout(() => {
                                setChecking(true)
                                setSearchTerm("")
                                if (res.data.button === 'final') {
                                    dispatch(quizRenders())
                                    router('/game/stage')
                                }
                            }, 1500);

                        }
                        if (res?.data?.match === 'Success') {
                            setIsCorrect(res.data.match);
                            setTimeout(() => {
                                setChecking(true)
                                setSearchTerm("")
                                dispatch(quizRenders())
                                router('/game/stage')
                            }, 3000);

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        dispatch(mapFunctionCallFalse())
                        dispatch(quizRendersfalse())
                        dispatch(MapRemoveBylocalstorage())
                        dispatch(RemovereduxDateFunc())
                        dispatch(message(error?.status))
                        router('/')
                    })

                if (isCorrect === 'Wrong') {
                    if ('vibrate' in navigator) {
                        // Vibrate for 200 milliseconds
                        navigator.vibrate(1000);

                    } else {
                        console.warn('Vibration API not supported in this browser.');
                    }
                }

            } catch (err) {
                console.log(err);
                if (err.name === 'SyntaxError') {
                    // Handle parsing error, e.g., show a user-friendly message
                    console.error('Parsing error. The server did not return valid JSON.');
                }
            }
        }


    };
    // const handleImageLoad = () => {
    //     setIsLoaded(true);
    // };

    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
      };
    
      const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
      };



    return (
        <>
            <div className="p-6 relative">


                <>
                    <div className="mx-auto w-[350px]">
                        <p className=" py-1 pb-6 text-[14px] font-normal text-center">
                            {
                                t("quizheader")
                            }
                        </p>
                    </div>

                    <div className="mx-auto flex items-center justify-evenly gap-5 md:gap-0 md:w-[440px] relative w-[312px] h-[280px] md:mb-3" style={{ direction: 'ltr' }}>
                        {
                            showModal &&
                            <div className="absolute w-[300px] h-[300px] bg-white rounded-xl overflow-hidden">
                            <div className="absolute right-2 top-2 bg-white p-2 rounded-full shadow-xl" onClick={closeModal}> 
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.875 1.125L1.125 10.875M1.125 1.125L10.875 10.875" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              
                            </div>
                            <div className="w-full h-full ">
                              <img className="w-full h-full" src={selectedImage} style={{objectFit:'content'}} alt="zoomed hints" />
                            </div>
                        </div>
                        }

                        <div className=" w-full">
                            <div className="w-full mb-2">
                                <div className="mx-auto w-[140px] h-[140px] rounded-[10px] overflow-hidden" onClick={() => openModal(mapDatas?.hints[0]?.hintone)}>
                                    <img className="w-full h-full" src={mapDatas?.hints[0]?.hintone} style={{ objectFit: 'cover' }} alt="hint0" />
                                </div>
                            </div>
                            <div className="flex justify-center gap-3">
                                <div className=" w-[140px] h-[140px] overflow-hidden rounded-[10px] text-center flex justify-center items-center text-white bg-gradient-to-r from-[#6308E8] to-[#AD6FD9]" >

                                    {
                                        attempt >= 1 ?
                                            <img onClick={() => openModal(mapDatas?.hints[1]?.hinttwo)} className="w-full h-full" src={mapDatas?.hints[1]?.hinttwo} style={{ objectFit: 'cover' }} alt="hint1" />
                                            :
                                            <p className={`${i18n.language === 'en' ? 'px-2' : 'text-[13px] px-2'}`}>? <br />{t('guestunclock')}</p>
                                    }

                                </div>
                                <div className=" w-[140px] h-[140px] overflow-hidden rounded-[10px] text-center flex justify-center items-center text-white bg-gradient-to-r from-[#6308E8] to-[#AD6FD9]" >

                                    {
                                        attempt >= 2 ?
                                            <img onClick={() => openModal(mapDatas?.hints[2]?.hintthree)} className="w-full h-full" src={mapDatas?.hints[2]?.hintthree} style={{ objectFit: 'cover' }} alt="hint1" />
                                            :
                                            <p className={`${i18n.language === 'en' ? 'px-2' : 'text-[13px] px-2'}`}>? <br /> {t('guestunclock')}</p>
                                    }

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="relative md:mb-4 mt-4">
                        <div className=" mx-auto pt-2 max-h-[260px] w-[312px] md:w-[440px] overflow-auto ">


                            {incorrectGuesses?.length > 0 && checking &&
                                incorrectGuesses.slice().reverse().map((data, id) => {
                                    return (
                                        <div key={id} className="relative">

                                            <div className=" rounded-full bg-gray-100 h-[40px] font-normal flex mb-[14px] justify-around items-center">
                                                <div className="truncate w-[130px]  h-[20px] text-base md:pl-2 pl-6">{i18n.language === 'en' ? data?.searchcityname : data?.tm_searchcityname}</div>
                                                <div className="w-[100px] h-[20px] flex items-center text-xs">
                                                    <p >{data?.distance_km} <span className={`${i18n.language === 'en' ? '' : 'text-[8px]'} w-full`}>{t("kmaway")}</span></p>
                                                </div>
                                                <div className={`w-[20px] h-[20px]`} >
                                                    <img style={{ rotate: data?.direction + 65 + 'deg' }} src="/arrow.svg" alt="arrow" />
                                                </div>
                                            </div>
                                            <span className="absolute  flex justify-center items-center left-0 bg-red-600 rounded-full top-2  h-6 w-6  text-[14px] text-white font-normal">{id + 1}</span>
                                        </div>


                                    );
                                })}


                        </div>

                    </div>

                    {
                        checking ?

                            <div className='relative md:w-[440px] w-[312px] md:h-[60px] h-[65px]  mx-auto'>

                                <input
                                    type="text"
                                    placeholder={cityPlaceholder === '' || cityPlaceholder === undefined ? t('cityplaceholder') : cityPlaceholder}
                                    ref={inputRef}
                                    onChange={handleSearchChange}
                                    className={`relative ${(cityPlaceholder === '' || cityPlaceholder === undefined) ? 'placeholder-gray-500' : attempt > 0 ? 'placeholder-gray-500' : 'placeholder-black'}  z-20 text-center outline-none text-black input border-none bg-gray-100 w-full h-full focus:outline-none focus:bg-slate-200 rounded-xl`}
                                />

                                <button
                                    className={
                                        submitEnabled
                                            ? "z-30 btn my-auto outline-none focus:outline-none border-none bg-amber-400 md:h-[53px] md:w-[53px] h-[57px] w-[57px] absolute top-0 right-1 bottom-0 rounded-xl"
                                            : "hidden"
                                    }
                                    onClick={checkCity}
                                >
                                    <FaPlay />
                                </button>
                                <div className="absolute right-3 py-2  text-[#0A0028]/50 text-xs font-bold">{(incorrectGuesses?.length)}/3</div>
                            </div>


                            :
                            <div className={`relative mx-auto  transition-all z-[99]  md:w-[440px] w-[312px] duration-300 ease ${checking ? 'h-[40px] opacity-0' : 'opacity-100'} 
                            ${!isLoading ? (isCorrect === 'Success' ? 'bg-[#BCF285]' : (isCorrect === 'Wrong' ? 'animate-flicker bg-[#FFD0D0]' : '')) : ''}  md:h-[60px] h-[65px]   rounded-xl text-base flex justify-center items-center `}>
                                {isCorrect === 'Success' &&
                                    <>
                                        <div className="absolute w-[107px] left-0 h-[105px]">
                                            <img
                                                src="/gif1.gif"
                                                alt="winner" />
                                        </div>
                                        <div className="absolute w-[107px] right-0 h-[105px]">
                                            <img
                                                src="/gif1.gif"
                                                alt="winner" />
                                        </div>
                                    </>
                                }
                                {
                                    isLoading ? <LoadingSpinner /> : searchTerm
                                }
                            </div>


                    }
                    <div >
                        <>
                            {isCityListVisible &&
                                cities?.map((city) => {
                                    return (
                                        <div key={city.id} className="mx-auto md:w-[440px] w-[312px] bg-[#F4F4F4] rounded-[8px]">
                                            <div
                                                onClick={() => handleCitySelect(city)}

                                                className=" cursor-pointer h-[32px] text-center my-1">{i18n.language === 'ta' ? city?.tm_constituencies_name :  city.constituencies_name}</div>
                                        </div>
                                    )
                                })
                            }
                        </>


                    </div>
                </>
                <div className="md:fixed md:bottom-0 pt-2 md:right-0">
                    <img src="/kiskol.png" alt="" className="w-full p-6 justify-end md:w-72" />
                </div>



            </div >

        </>
    );
};

export default QuizPage;
