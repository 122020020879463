import React, { useState } from 'react'
import UserIcons from '../../components/UserIcons'
import { useGetCaunstituenctyListQuery } from '../../slices/userApiSlice';
import { useTranslation } from 'react-i18next';
import ElectoralSLider from '../../components/ElectoralSlider/ElectoralSLider';

const Electoral = () => {
    const [leftSlider, setLeftSlider] = useState(false)
    const [ElectoralID, setElectoralID] = useState({
        id: "",
        tm_name: "",
        name: ""
    })
    const { data, error, isLoading } = useGetCaunstituenctyListQuery();
    const { i18n, t } = useTranslation();

    const handleSlider = (id, tm_name, name) => {
        setLeftSlider(!leftSlider)
        setElectoralID({
            id, tm_name, name
        })
    }

    return (
        <div className="md:pt-6 pb-6 min-h-[calc(100vh-100px)]">
            <UserIcons auth={true} name={t("electoral")} />
            <div className={`relative rounded-3xl bg-white ${leftSlider ? ElectoralID.id === 1 ? 'h-[2620px] ' : 'h-[1600px]' : 'xl:h-[600px] h-[1000px]'} md:w-[600px] xl:w-[1200px] mx-auto overflow-hidden`}>
             
                    <div className='py-5'>
                        <p className='text-[14px] text-[#0A0028] text-center w-[312px] mx-auto'>{t("electoralpara")}</p>
                    </div>
                    <div className={`h-[90%] overflow-auto ${leftSlider ? 'opacity-0' : ''}`}>
                        {
                            data?.data?.map(({ id, constituencies_name, tm_constituencies_name }) => {
                                return (
                                    <div onClick={() => handleSlider(id, tm_constituencies_name, constituencies_name)} key={id} className='w-full py-2 flex items-center justify-between px-6 hover:bg-slate-100'>
                                        <p className='text-lg text-[#0A0028] font-medium'>{i18n.language === 'en' ? constituencies_name : tm_constituencies_name}</p>
                                        <span>
                                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.5" d="M6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967L1.75736 0.696699C1.46447 0.403806 0.989593 0.403806 0.696699 0.696699C0.403806 0.989593 0.403806 1.46447 0.696699 1.75736L4.93934 6L0.696699 10.2426C0.403806 10.5355 0.403806 11.0104 0.696699 11.3033C0.989593 11.5962 1.46447 11.5962 1.75736 11.3033L6.53033 6.53033ZM5 6.75H6V5.25H5V6.75Z" fill="black" />
                                            </svg>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
              
                <ElectoralSLider leftSlider={leftSlider} handleSliderProps={handleSlider} electoralDetails={ElectoralID} stage={true} />
            </div>

           

        </div>
    )
}

export default Electoral