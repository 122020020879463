import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import UserIcons from '../../components/UserIcons';

const ErrorMessage = () => {
    const { message } = useSelector(state => state.auth);
    const navigate = useNavigate()
    return (
        <div className="md:py-6">
            <div className="rounded-3xl bg-white w-[328px] md:w-[600px]  mx-auto">
                <div className="py-8">
                    <img
                        src="/Kaunsituency.svg"
                        alt=""
                        className="mx-auto w-[119px] h-[75px]"
                    />
                </div>
                <div className=" rounded-3xl w-full">
                    <div className={`transition-all overflow-hidden ease-in-out duration-500  w-full`}>
                        <div className={`text-[#0A0028] w-full text-[13px] md:text-base px-4 md:px-6`}>
                            <p className='text-semobold text-red-500 text-lg'>Oops! Something went wrong.</p>

                            <p className='py-2'>We're sorry, but it seems like there's an issue accessing the quiz game right now. This could be due to a temporary server glitch or maintenance.</p>

                            <p className='py-2'>Here's what you can do:</p>

                            <p className='py-2'>1. Please check your internet connection and try again.</p>
                            <p className='py-2'>2. If the problem persists, wait for a few minutes and try again later.</p>
                            <p className='py-2'>3. Clear your browser's cookies and local storage data:</p>
                            <p className='ml-4 py-1'>- For most browsers, you can do this by going to your browser settings or preferences.</p>
                            <p className='ml-4 py-1'>- If you're unsure how to do this, you can find helpful guides online.</p>
                            <p className='py-2'>4. If the issue continues, contact our support team at support@example.com for assistance.</p>
                            <p className='py-2 text-red-500 text-semibold'>Error : {message}</p>

                            We apologize for any inconvenience and appreciate your patience as we work to resolve this issue. Thank you for playing our quiz game!
                        </div>
                    </div>
                    <div className={`w-full`}>
                        <div className="md:mt-10 mt-10">

                            <div onClick={() => navigate('/auth/welcome')}>
                                <div className="cursor-pointer mb-6 mx-auto  md:w-[444px] w-[288px] h-[50px] rounded-[66px]  bg-[#D9D9D9] hover:bg-[#D9D9D9]/90 flex justify-center items-center gap-2">
                                    <img src="/help.svg" className="w-5 h-5" alt="play" />
                                    <p className="text-[#0A0028] text-[14px] font-normal">Go to Back</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ErrorMessage