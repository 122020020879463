import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'



// Define a service using a base URL and expected endpoints
export const userApiSlice = createApi({
    reducerPath: 'userApiSlice',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://kaunsituency.com/minmini/api/v2/',
        //  baseUrl:'https://kaunsituency-staging.dtplappapi.com/backend/api/v2/',
        // baseUrl:'https://areaking.minmini.app/backend/api/v2/'
        baseUrl: 'http://167.71.174.132/minmini/api/v2/'
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: 'login',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        authenticationLogin: builder.query({
            query: (token) => {
                return {
                    url: `user/${token}`,
                    method: 'GET',
                }
            },

        }),
        users: builder.mutation({
            query: (data) => ({
                url: 'user-profile',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        userscheck: builder.mutation({
            query: (data) => ({
                url: 'user-check',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        otp: builder.mutation({
            query: (data) => ({
                url: 'verify-otp',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        resendotp: builder.mutation({
            query: (data) => ({
                url: 'resend-otp',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        update: builder.mutation({
            query: (data) => ({
                url: 'update-profile',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        updateProfileImage: builder.mutation({
            query: (data) => ({
                url: 'update-profile-img',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        gmail: builder.mutation({
            query: (data) => ({
                url: 'gmail-login',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        guest: builder.mutation({
            query: () => {
                return {
                    url: `user-guest`,
                    method: 'GET',
                }
            },

        }),
        search: builder.mutation({
            query: (data) => ({
                url: 'search',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        gamemap: builder.mutation({
            query: (data) => ({
                url: 'game-map',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        cityData: builder.mutation({
            query: (data) => ({
                url: 'city-data',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        cityDataComplete: builder.mutation({
            query: (data) => ({
                url: 'complete-city-data',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        cityReload: builder.mutation({
            query: (data) => ({
                url: 'reload-city-data',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        congratulations: builder.mutation({
            query: (data) => ({
                url: 'congratulations',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        profile: builder.mutation({
            query: (data) => ({
                url: 'user-leader-board',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        getLeaderboard: builder.query({
            query: (count) => {
                return {
                    url: `leader-board/${count}`,
                    method: 'GET',
                }
            },

        }),
        getuserLeaderboard: builder.query({
            query: (userid) => {
                return {
                    url: `user-position-leader-board/${userid}`,
                    method: 'GET',
                }
            },

        }),
        previousGame: builder.mutation({
            query: (data) => ({
                url: 'previous-game-map',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        gameCalender: builder.mutation({
            query: (data) => ({
                url: 'map-calendar',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        playedCount: builder.mutation({
            query: (data) => ({
                url: 'user-game-count',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),

        logout: builder.mutation({
            query: (data) => ({
                url: 'log-out',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        candidatelist: builder.mutation({
            query: (data) => ({
                url: 'predict-cuddalare',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        predict: builder.mutation({
            query: (data) => ({
                url: 'predict-candidates',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
        getCaunstituenctyList: builder.query({
            query: (count) => {
                return {
                    url: `all-citys`,
                    method: 'GET',
                }
            },

        }),
        electoralHistory: builder.mutation({
            query: (data) => ({
                url: 'electoral-history',
                method: 'POST',
                body: data,
                headers: {
                    'Content-type': 'application/json',
                }
            }),
        }),
    })

})
export const {
    useLoginMutation,
    useOtpMutation,
    useUpdateMutation,
    useLogoutMutation,
    useGmailMutation,
    useSearchMutation,
    useGamemapMutation,
    useCityDataMutation,
    useCongratulationsMutation,
    useProfileMutation,
    useGetLeaderboardQuery,
    useGetuserLeaderboardQuery,
    useResendotpMutation,
    useCityReloadMutation,
    usePreviousGameMutation,
    useGameCalenderMutation,
    useGuestMutation,
    usePlayedCountMutation,
    useUsersMutation,
    useUserscheckMutation,
    useCityDataCompleteMutation,
    useUpdateProfileImageMutation,
    useCandidatelistMutation,
    useGetCaunstituenctyListQuery,
    useElectoralHistoryMutation,
    useAuthenticationLoginQuery,
    usePredictMutation
} = userApiSlice


