import React from 'react';
import CustomeIcons from './icon/CustomeIcons';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import { useDispatch, useSelector } from 'react-redux';
import { MdExitToApp } from "react-icons/md";
import { MapRemoveBylocalstorage, RemovereduxDateFunc, indiaMapOpenAndClose, mapFunctionCallFalse, quizRendersfalse } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Help from './help/Help';

import { useTranslation } from 'react-i18next';
import LanguageSelector from './language-selector/languageSelector';


const DesktopNav = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const { mapCall } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleExits = () => {
        dispatch(quizRendersfalse())
        dispatch(MapRemoveBylocalstorage())
        dispatch(RemovereduxDateFunc())
        dispatch(mapFunctionCallFalse())
        navigate('/')
    }
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

    const openModal = () => {
        setIsOpen(true);
        console.log('ok');
    };
   

    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
      
            <Help isOpen={isOpen} closeModal={closeModal} />
            {

                <div className='bg-white h-[100px]  hidden lg:block w-full '>
                    <div className='h-full w-full flex justify-between px-20 items-center'>
                        <img src='/Kausituencywhite.png' className='h-[60px]' alt='logo' />
                     
                        {
                            mapCall ?
                                <div className='flex gap-6 items-center'>
                                     <div onClick={() => dispatch(indiaMapOpenAndClose())}><CustomeIcons icon="/india.svg" text={t("refermap")} /></div>
                                    <div onClick={openModal}><CustomeIcons icon="/question.svg" text={t("help")} /></div>
                                    <LanguageSelector color='text-black' />
                                    {/* <div onClick={handleExits} className='cursor-pointer w-[180px] flex justify-center items-center h-[50px] top-0 rounded-[30px] transition-all bg-[#FDC504]'>
                                        <p className='text-base text-semibold text-black'>Exit Game</p>
                                        <span className='text-lg mt-0.5 ml-2 font-[900]' ><MdExitToApp /></span>
                                    </div> */}
                                </div>
                                :
                                userInfo?.role === 'guest' ?
                                    <div className='flex gap-6 items-center'>
                                        <CustomeIcons icon="/home.svg" text="Home" link="/" />
                                        <div onClick={openModal}><CustomeIcons icon="/question.svg" text={t("help")} /></div>
                                        <LanguageSelector color='text-black' />
                                        <CustomeIcons icon="/phone.svg" text={t("contactus")} />
                                        

                                    </div>
                                    :
                                    <div className='flex gap-6 items-center'>
                                        {
                                            userInfo !== null && <CustomeIcons icon="/home.svg" text={t("home")} link="/" />
                                        }
                                        {
                                            userInfo && userInfo.name && <CustomeIcons icon="/leaderboard.svg" text={t("leaderboard")} link="/auth/leaderboard" />
                                        }
                                        <CustomeIcons icon="/phone.svg"  text={t("contactus")} />
                                        <div onClick={openModal}><CustomeIcons icon="/question.svg" text={t("help")} /></div>
                                        
                                        {
                                            userInfo && userInfo.name && <ProfileMenu />
                                        }
                                        <LanguageSelector color='text-black' />

                                    </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default DesktopNav;
