import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import GameboardLayout from "./pages/gamebord/GameboardLayout";
import Welcome from "./pages/stats/Welcome";
import QuizPage from "./pages/gamebord/QuizPage";
import StagePage from "./pages/gamebord/StagePage";
import DesktopNav from "./components/DesktopNav";
import Profile from "./pages/stats/Profile";
import Previous from "./pages/stats/Previous";
import Leaderboard from "./pages/stats/Leaderboard";
import Notfound from "./pages/Error/Notfound";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ErrorMessage from "./pages/gamebord/ErrorMessage";
import SharePage from "./pages/stats/SharePage";
import { Toaster } from 'react-hot-toast';
import PredictPage from "./pages/gamebord/PredictPage";
import Electoral from "./pages/stats/Electoral";
import { useTranslation } from "react-i18next";
import { MapRemoveBylocalstorage, RemovereduxDateFunc, logout, mapFunctionCallFalse, quizRendersfalse, setCredentials } from "./slices/authSlice";
import { useAuthenticationLoginQuery } from "./slices/userApiSlice";
import ReactGA from "react-ga4";




function App() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const Islogout = queryParams.get('isLogout');
    const { mapCall } = useSelector((state) => state.auth);
    const { quizRender } = useSelector((state) => state.auth);
    const { data, isLoading, isError } = useAuthenticationLoginQuery(token);
    const dispatch = useDispatch()
   
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (mapCall) {
    //         if (quizRender) {
    //             navigate('/game/quiz')
    //         }
    //         else {
    //             navigate('/game/stage')
    //         }

    //     }
    // }, [])
    const authRoutes = [
        { path: 'profile', element: <Profile /> },
        { path: 'previous', element: <Previous /> },
        { path: 'leaderboard', element: <Leaderboard /> },
        { path: 'share', element: <SharePage /> },
        { path: 'electrol', element: <Electoral /> },
    ];

    const { i18n } = useTranslation()

    useEffect(() => {
       if(data && !isLoading && !isError){
        dispatch(setCredentials(data?.data))
       }
    },[])
    useEffect(() => {
        if (token !== null) {
            if (data && !isLoading && !isError) {
                if (Islogout === 'true') {
                    dispatch(logout())
                    dispatch(mapFunctionCallFalse())
                    dispatch(quizRendersfalse())
                    dispatch(MapRemoveBylocalstorage())
                    dispatch(RemovereduxDateFunc())
                    navigate('/')
                }
                else {
                    console.log(Islogout);
                    if (data?.data !== undefined) {
                        dispatch(mapFunctionCallFalse())
                        dispatch(quizRendersfalse())
                        dispatch(MapRemoveBylocalstorage())
                        dispatch(RemovereduxDateFunc())
                        dispatch(setCredentials(data?.data))
                    }
                    else {
                        dispatch(logout())
                        dispatch(mapFunctionCallFalse())
                        dispatch(quizRendersfalse())
                        dispatch(MapRemoveBylocalstorage())
                        dispatch(RemovereduxDateFunc())
                        navigate('/')
                    }
                }


            }
        }
        else {
            if (Islogout === 'true') {
                dispatch(logout())
                dispatch(mapFunctionCallFalse())
                dispatch(quizRendersfalse())
                dispatch(MapRemoveBylocalstorage())
                dispatch(RemovereduxDateFunc())
                navigate('/')
            }

        }
    }, [data ,isLoading, isError,token]);


  

    useEffect(() => {
        
        const getPageTitle = () => {
            const path = document.location.pathname;
    
            if (path === '') {
                return 'area king Homepage';
            }
            else if (path === '/game/quiz') {
                return 'area king Play Game';
            } else if (path === '/auth/profile') {
                return 'area king My Stats';
            } else if (path === '/auth/previous') {
                return 'area king Play Previous Games';
            } else if (path === '/auth/leaderboard') {
                return 'area king Leaderboard';
            }  else if (path === '/game/stage') {
                return 'area king Congratulations page';
            }
            else {
                return 'area king Page Not Found';
            }
        };
        const pathname = document.location.pathname;
        const title = getPageTitle(pathname);

        // Initialize and send page views for both tracking IDs
        const TRACKING_ID_1 = 'G-YL0H5573EW';
        ReactGA.initialize(TRACKING_ID_1);
        ReactGA.send({ hitType: "pageview", page: pathname, title: title });

        const TRACKING_ID_2 = 'G-DEWH8SN0W4';
        ReactGA.initialize(TRACKING_ID_2);
        ReactGA.send({ hitType: "pageview", page: pathname, title: title });
    }, [location]);
    return (
        <>
            <Toaster />
            {
                <div className="fiexd top-0" style={{ direction: i18n.language === 'ur' ? 'rtl' : '' }}>
                    <div className="font-poppins bg-gradient-to-r from-[#8657F3] to-[#541AD9]  overflow-hidden min-h-screen">
                        <DesktopNav />
                        <Routes>

                            <Route path="" element={<Welcome />} />
                            <Route path="game" element={<GameboardLayout />}>
                                <Route path="quiz" element={<QuizPage />} />
                                <Route path="stage" element={<StagePage />} />
                                <Route path="message" element={<ErrorMessage />} />
                                <Route path="prediction" element={<PredictPage />} />
                            </Route>
                            <Route path='auth'>
                                {
                                    authRoutes.map(route => (
                                        <Route key={route.path} path={route.path} element={route.element} />
                                    ))
                                }

                            </Route>
                            <Route path="*" element={<Notfound />} />
                        </Routes>
                    </div>
                </div>
            }

        </>
    );
}

export default App;
