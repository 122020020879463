
import React from 'react'
import {  Link, useNavigate } from 'react-router-dom'




const UserIcons = ({ name }) => {
    return (
        <div className="my-10 justify-between  mx-4 flex lg:hidden ">
            <div className="flex gap-2">
                {
             

                        <>
                            <Link to="/" >
                                <img
                                    src="/arrowback.svg"
                                    alt="arrow back"
                                />
                            </Link>
                            <p className="my-auto ml-2 text-lg font-medium text-white">
                                {name}
                            </p>

                        </>
                }
            </div>
          
        </div>
    )
}

export default UserIcons